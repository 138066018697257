import { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import styled from 'styled-components';
import axios from "axios";

const HotelDetail = () => {
 
    const [validated, setValidated] = useState(false);
    const[statevalue, setstatevalue]=useState('no');

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            console.log('empty');
        }else{
            event.preventDefault();
            console.log(statevalue);
            const needHotel = event.target[1].value;
            
            let numberOfHotel = '';
            let hotelDetails = '';
            if(statevalue == "yes"){
             numberOfHotel = event.target[2].value;
             hotelDetails  = event.target[3].value;                      
            }

            const HotelData = {
                traveler_id: 123,
                needHotel: statevalue,
                numberOfHotel: numberOfHotel,
                hotelDetails: hotelDetails,
            };

            axios
              .post("https://rogerapi.app2dev.in/api/hoteldetails", HotelData, {
                  headers: {
                    'content-type': 'multipart/form-data',
                  withCredentials: true,
                  },
              })
              .then((response) => {
                  if (response.data.status == "200") {        
                    alert('Details Submitted');
                    console.log('success');
                  }
                  if (response.data.status == "401") {                      ;
                    const dataError = response.data.message;
                    alert(dataError);
                  }
              })
              .catch((error) => {
                  console.error(error); // Handle any errors
                })

            console.log(HotelData);
        }
        setValidated(true);
    };
    return (
        <Wrapper>
            <Container>
                <div className='travel-input'>
                    <Row>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <h2 className="text-center mb-4">Hotel Details</h2>
                        <Col md={12}>

                            <div className="gfield_description">Do You Need A Hotel Booking For Each Traveler As Well?</div>
                            <Form.Label> Proof of accommodation is mandatory for Schengen visa. We take care of all bookings and cancellations for your visa application needs.</Form.Label>
                           
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            value="yes"
                                            name="group1"
                                            type={type}
                                            checked={statevalue === 'yes'}
                                            id={`inline-${type}-1`}
                                            onClick={e => setstatevalue(e.currentTarget.value)}
                                        />
                                        <Form.Check
                                            inline
                                            label="No"
                                            value="no" 
                                            name="group1"
                                            checked={statevalue === 'no'}
                                            type={type}
                                            id={`inline-${type}-2`}
                                            onClick={e => setstatevalue(e.currentTarget.value)}

                                        />
                                    </div>
                                ))}
                            
                        </Col>

                        {statevalue==='yes'?(

                           <>
                        <Form.Group as={Col} md="6" className='mb-4' controlId="validationCustomUsername">
                            <Form.Label>No. Of Hotels*</Form.Label>
                            <Form.Select required aria-label="Default select example">

                                <option value="1" selected="selected">1 - 3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                            </Form.Select>
                        </Form.Group>
                        

                        <Form.Group as={Col} md="12" controlId="validationCustomUsername">

                            <div className="gfield_description">Provide Travelers Hotel Details:*</div>
                            <Form.Label>City - Checkin date - Checkout date</Form.Label>
                            <Form.Control required
                                as="textarea"
                                placeholder="City - Frankfurt, Germany; Check in date - June 1, 2017; Check out date - Jun 10, 2017 or Type &quot;Please see attachment&quot;"
                                style={{ height: '200px' }}
                            />
                        </Form.Group>
                        <Col md={12}>
                            <div className="flight-prize">
                                <div className="flight-p">Your Hotel Cost Is: <br />
                                    <div className="gfield_description" id="gfield_description_87_62">(Upto 3 hotels only. Additional hotels @ $10/hotel. Max stay upto 3 weeks/hotel)</div>
                                </div>
                                <div className="total-value">$40</div>
                            </div>
                        </Col>
                        </>

                        ):null}

                        <Button type="submit" className='custom-btn btn-3'><span>Submit</span></Button>
                       </Form>
                    </Row>
                </div>
            </Container>
        </Wrapper>
    )
}

export default HotelDetail
const Wrapper = styled.section`
font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
    .travel-input {
	padding: 50px;
	background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    .flight-prize {
    display: flex;
    justify-content: space-between;
    font-size: 25px;
    font-weight: 500;
    position: relative;
}
.flight-p:before {
    content: "";
    position: absolute;
    width: 72%;
    height: 3px;
    top: 30px;
    text-align: center;
    left: 275px;
    border: 2px dotted #069460;
}
.total-value {
    text-transform: uppercase;
    font-size: 35px;
    font-weight: 700;
    color: #069460;
}
.gfield_description {
    font-size: 14px;
    font-weight: 500;
}
.flight-p {
    padding: 7px;
}
label{
    margin-bottom:5px;
}
    
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
        .flight-p {
            padding: 7px;
            &: before
             {
                display:none;
           
        }
        }
        @media (max-width: ${({ theme }) => theme.breakpoints.medium}) { 
            .travel-input {
           padding: 15px;
           
       }
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.small}) { 
        .travel-input {
        .flight-prize {
            font-size:19px;
            display:block;
            .total-value {
                font-size: 35px;
                color: #069460;
                text-align: center;
                background: #bbf3e0;
            }
            
        }
        
    }

`;