import React from 'react'
import FillDetails from "../components/BookAppointment/FillDetails";
import FlightReservation from '../components/BookAppointment/FlightReservation'
import VisaAppointment from '../components/BookAppointment/VisaAppointment'
import AppointmentDetails from '../components/BookAppointment/AppointmentDetails'
import OrderSummary from '../components/BookAppointment/OrderSummary'
import PaymentMethod from '../components/BookAppointment/PaymentMethod'
import VisaApplication from '../components/BookAppointment/VisaApplication'
import TravelGuides from '../components/BookAppointment/TravelGuides'
import InsuranceInformation from '../components/BookAppointment/InsuranceInformation'
import HotelDetail from '../components/BookAppointment/HotelDetail'





const BookAppointment = () => {
  return (
    <>
      <FillDetails />
      <FlightReservation />
      <VisaAppointment />
      <AppointmentDetails />
      <HotelDetail/>
      <InsuranceInformation/>
      <TravelGuides/>
      <VisaApplication/>
      <OrderSummary />
      <PaymentMethod />
      

    </>
  )
}

export default BookAppointment