import React from 'react'
import ContactForm from '../components/Contact/ContactForm'
import ContactBanner from "../components/Contact/ContactBanner";

const ContactUs = () => {
    return (
        <>
        <ContactBanner/>
       <ContactForm/>
       </>
    )
}

export default ContactUs



