import React from 'react'
import { Col, Container, Row,Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'


const ContactNow = () => {
  return (
    <Wrapper>
        <Container>
            <Row>
                <Col md={12}>
                <div className="faqq-2 text-center"> 
                <h2> Did Not Find the Answer You <br/> Were Looking for? </h2> 
                <Button variant="contained">Contact us now</Button>
                <p> or call </p> 
                <p> <b><Link to="#">+1 (919)-263-2551</Link></b>
                 </p> 
                 </div>
                </Col>
            </Row>

        </Container>

    </Wrapper>
  )
}

export default ContactNow
const Wrapper = styled.section`
padding:60px 0px;
.faqq-2{
    a{
        font-size: 20px;
        color: #000000;
        text-decoration: none;
    }
}
.btn-contained {
    color: #fff;
    width: 200px;
    border-radius: 20px;
    margin: 15px 0px;
}

`;
