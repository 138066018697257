import React from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import styled from 'styled-components'
import amex from '../../assets/images/amex.png'
import discover from '../../assets/images/discover.png'
import master from '../../assets/images/master.png'
import visa from '../../assets/images/visa.png'
import PayPal from '../../assets/images/PayPal.png'
import logomedia from '../../assets/images/logomedia.png'
import { Link } from 'react-router-dom';
import { FaCheck } from "react-icons/fa6";



const PaymentMethod = () => {
    return (
        <Wrapper>
            <Container>
                <div className='travel-input'>
                    <Row>
                        <Col md={7} className='paypal-sec1'>
                            <Form.Label>Choose a payment method: *</Form.Label>
                            <Form>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check
                                            inline
                                            label="Pay with PayPal"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-1`}
                                        />
                                        <Form.Check
                                            inline
                                            label="Pay with Credit/Debit Card"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-2`}
                                        />
                                    </div>
                                ))}
                            </Form>
                            <div className="card-details">
                                <label className="gform-field-label">Credit Card*</label>
                                <div className="gform_card_icon_container">
                                    <div>
                                        <img src={amex} />
                                    </div>
                                    <div>
                                        <img src={discover} />
                                    </div>
                                    <div>
                                        <img src={master} />
                                    </div>
                                    <div>
                                        <img src={visa} />
                                    </div>
                                </div>
                            </div>
                            <div className="card-data flex-fill flex-vertical">
                                <div className="flex-between flex-vertical-center">
                                    <div className="card-property-title  mt-3">
                                        <strong>Card Number</strong>
                                        <span>Enter 16-digit card number on the card</span>
                                    </div>
                                </div>
                                <div className="flex-between">
                                    <div className=" flex-vertical-center flex-fill">
                                        <div className=" flex-vertical-center flex-fill">
                                            <Form.Control
                                                required
                                                type="number"
                                                placeholder="Card Number"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-between">
                                    <div className="card-property-title">
                                        <strong>Expiry Date</strong>
                                        <span>Enter the expiration date of the card</span>
                                    </div>
                                    <div className="card-property-value flex-vertical-center">
                                        <div className="input-container half-width">

                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="MM"
                                            />
                                        </div>
                                        <span className="m-md">/</span>
                                        <div className="input-container half-width">
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="YYYY"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-between">
                                    <div className="card-property-title">
                                        <strong>CVC Number</strong>
                                        <span>Enter card verification code from the back of the card</span>
                                    </div>
                                    <div className="card-property-value">
                                        <div className="input-container">

                                            <Form.Control
                                                required
                                                type="password"
                                                placeholder="Card CVV"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-between">
                                    <div className="card-property-title">
                                        <strong>Cardholder Name</strong>
                                        <span>Enter cardholder's name</span>
                                    </div>
                                    <div className="card-property-value">
                                        <div className="input-container">
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="CARDHOLDER NAME"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-between">
                                    <div className="card-property-title">
                                        <strong>Billing Zip / Postal Code*</strong>
                                        <span>Enter Billing Zip / Postal Code</span>
                                    </div>
                                    <div className="card-property-value">
                                        <div className="input-container">
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Billing Zip / Postal Code"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="action flex-center">
                                <Button variant="contained">Pay Now</Button>
                            </div>
                        </Col>

                        <Col md={5} className='py-5 '>

                            <div className="paypal-sec">
                                <ul>

                                    <li>
                                        <div>
                                            <div>
                                                <p>
                                                    <span><FaCheck /></span>
                                                    100% money back guarantee through Paypal
                                                </p>

                                                <p>
                                                    <span><FaCheck /></span>
                                                    <Link to="#" target="_self">
                                                        Confirmed and verifiable itinerary
                                                    </Link>
                                                </p>
                                                <p>
                                                    <span><FaCheck /></span>
                                                    Option to pay using <strong>debit/credit card</strong>
                                                </p>

                                            </div>
                                            <div className="protection_ins_image">
                                                <img src={PayPal} />
                                            </div>
                                            <br />
                                            <img src={logomedia} />
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Wrapper>
    )
}

export default PaymentMethod
const Wrapper = styled.section`
font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
    .travel-input {
	padding: 50px;
	background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    .gform_card_icon_container img {
    width: 60px;
}
.gform_card_icon_container {
    display: flex;
}

.card-property-title {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    margin-right: 0.5em;
}
.flex-vertical-center {
    display: flex;
    align-items: center;
}
.flex-between {
    display: flex;
    justify-content: space-between;
}
.card-data > div {
    padding-bottom: 1.5em;
}
.card-property-title span {
    font-size: 0.75em;
}
.input-container {
    position: relative;
    display: flex;
    align-items: center;
    height: 3em;
    overflow: hidden;
}
.input-container input {
    flex: 1 1;
    height: 100%;
    width: 100%;
    text-align: center;
    border: none;
    font-family: inherit;
    font-weight: 800;
    font-size: 0.85em;
    background: #fafafa;
}
.card-property-value {
    flex: 1 1;
}
img.logo-master {
    width: 24px;
}
 .btn-contained {
    width: 100%;
    color: #fff;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
}
.paypal-sec {
    padding: 50px;
    align-items: center;
    display: flex;
    span{
        margin-right: 10px;
    font-size: 15px;
    color: green
    }
    li{
        list-style:none;
    }
}
.paypal-sec1 {
    border-right: 1px solid#ccc;
}

    
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
        padding:0;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.small}) { 
        .travel-input {
       padding: 15px;
       .paypal-sec {
        padding: 15px;
      
    }
    .paypal-sec 
    ul {
        padding: 0;
    }
}
       
   }

}
`;
