import React from 'react'
import FillDetails from "../components/TravelDetails3/FillDetails";
import FlightReservation from "../components/TravelDetails3/FlightReservation"
import TravelersDetails from '../components/TravelDetails3/TravelersDetails'
import GeneralInformation from '../components/TravelDetails3/GeneralInformation'
import HotelDetail from '../components/TravelDetails3/HotelDetail'
import InsuranceInformation from '../components/TravelDetails3/InsuranceInformation'
import TravelGuides from "../components/TravelDetails3/TravelGuides"
import SchengenVisa from "../components/TravelDetails3/SchengenVisa"
import VisaApplication from "../components/TravelDetails3/VisaApplication"
import OrderSummary from "../components/TravelDetails3/OrderSummary"
import PaymentMethod from "../components/TravelDetails3/PaymentMethod";



const TravelDetails3 = () => {
    return (
        <>
            <FillDetails />
            <FlightReservation />
            <TravelersDetails />
            <GeneralInformation />
            <HotelDetail />
            <InsuranceInformation />
            <TravelGuides />
            <SchengenVisa />
            <VisaApplication />
            <OrderSummary />
            <PaymentMethod />


        </>
    )
}

export default TravelDetails3