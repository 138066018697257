import { useState } from 'react';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import axios from "axios";


const GeneralInformation = () => {
    const [validated, setValidated] = useState(false);

    const[hide, sethide] = useState('yes');
    const yes = 'yes';
    const no = 'no';

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let billingAccount = '';
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }else{
            event.preventDefault();
            const traveler_id = event.target[0].value;
            const visaDate = event.target[1].value;
            const consulate  = event.target[2].value;
            const hearFrom = event.target[3].value;
            console.log(hide);
            if(hide == "no"){
                 billingAccount = event.target[4].value;
            }

            const genaralData = {
                traveler_id: traveler_id,
                visaDate: visaDate,
                consulate: consulate,
                hearFrom: hearFrom,               
                makingPayment: hide,
                billingAccount: billingAccount,
            };

            console.log(genaralData);

            axios
              .post("https://rogerapi.app2dev.in/api/generalinformation", genaralData, {
                  headers: {
                    'content-type': 'multipart/form-data',
                  withCredentials: true,
                  },
              })
              .then((response) => {
                  if (response.data.status == "200") {        
                    alert('Details Submitted');
                    console.log('success');
                  }
                  if (response.data.status == "401") {                      ;
                    const dataError = response.data.message;
                    alert(dataError);
                  }
              })
              .catch((error) => {
                  console.error(error); // Handle any errors
                })
        }
        setValidated(true);
    };



    return (
        <Wrapper>
            <Container>
                <div className='travel-input'>
                    <Row>
                        <Col sm={12}>
                            <h2 className="text-center mb-4">General Information</h2>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" className='mb-4 interview-tp' controlId="validationCustom01">
                                        <Form.Control type="hidden" name="traveler_id" value="" id="traveler_id"/>
                                        <div className="gfield_description">Visa Interview Date</div>
                                        <Form.Label>If you don't have a date yet, leave it blank</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Visa Date"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4"className='mb-4' controlId="validationCustom02">
                                        <Form.Label>What Consulate Are You Applying At?</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Germany"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustomUsername">
                                        <Form.Label>How Did You Hear About Us?</Form.Label>
                                        <Form.Select required aria-label="Default select example">

                                            <option value="" selected="selected">Please Select</option>
                                            <option value="GermanyVisaOrg Website">GermanyVisaOrg Website</option>
                                            <option value="VisaGuideWorld Website">VisaGuideWorld Website</option>
                                            <option value="SchengenVisaInfo Website">SchengenVisaInfo Website</option>
                                            <option value="Google Ads">Google Ads</option>
                                            <option value="Visa Reservation Blog Article">Visa Reservation Blog Article</option>
                                            <option value="SchengenVisaCommunity Facebook Group">SchengenVisaCommunity Facebook Group</option>
                                            <option value="Youtube">Youtube</option>
                                            <option value="Discussion Board">Discussion Board</option>
                                            <option value="Social Media-Facebook, Twitter">Social Media-Facebook, Twitter</option>
                                            <option value="Quora">Quora</option>
                                            <option value="Friends & Family">Friends & Family</option>
                                            <option value="Google Search Engine">Google Search Engine</option>
                                            <option value="I'm not sure">I'm not sure</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Col md={6}>
                                        <Form.Label>Are You One Of The Travelers Making The Payment? *</Form.Label>
                                        <Form>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`} className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name="group1"
                                                        checked={hide === 'yes'}
                                                        value={yes}
                                                        type={type}
                                                        id={`inline-${type}-1`}
                                                        onChange={e => sethide(e.currentTarget.value)} />
                                                    <Form.Check
                                                        inline
                                                        label="No"
                                                        name="group1"
                                                        value={no}
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                        onChange={e => sethide(e.currentTarget.value)}/>
                                                </div>
                                            ))}
                                        </Form>
                                    </Col>


                                    {hide==='no' ?   
                                    <Col md={6}>
                                        <Form.Group controlId="validationCustom02">
                                            <Form.Label>Provide The Name On The Billing Account?*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter your name as you are making the payment"
                                            />
                                        </Form.Group>
                                    </Col>
                                    :null}

                                </Row>
                                <Button type="submit" className='custom-btn btn-3'><span>Submit</span></Button>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Wrapper>
    )
}

export default GeneralInformation
const Wrapper = styled.section`
font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
    .travel-input {
	padding: 50px;
	background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    .interview-tp {
    margin-top: -21px;
}
label{
    margin-bottom:5px;
}
    }
   

    @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
padding:0;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.medium}) { 
        .travel-input {
       padding: 15px;
       
   }
}
`;