import React from 'react'
import  FillDetails  from "../components/TravelDetails2/FillDetails";
import FlightReservation from '../components/TravelDetails2/FlightReservation'
import TravelersDetails from '../components/TravelDetails2/TravelersDetails'
import  GeneralInformation  from '../components/TravelDetails2/GeneralInformation'
import FlightDetails  from '../components/TravelDetails2/FlightDetails'
import InsuranceInformation from '../components/TravelDetails2/InsuranceInformation'
import  TravelGuides  from "../components/TravelDetails2/TravelGuides"
import SchengenVisa from "../components/TravelDetails2/SchengenVisa"
import VisaApplication from "../components/TravelDetails2/VisaApplication"
import OrderSummary  from "../components/TravelDetails2/OrderSummary"
import PaymentMethod from "../components/TravelDetails2/PaymentMethod";



const TravelDetails2 = () => {
  return (
    <>
    <FillDetails/>
    <FlightReservation/>
    <TravelersDetails/>
    <GeneralInformation/>
    <FlightDetails/>
    <InsuranceInformation/>
    <TravelGuides/>
    <SchengenVisa/>
    <VisaApplication/>
    <OrderSummary/>
    <PaymentMethod/>
    </>
  )
}

export default TravelDetails2