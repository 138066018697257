import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FlightReservation = () => {
  return (
    <Wrapper className="content faqq-2 serv__txt1">
      <Container>
        <Row>
          <Col sm={12} className='text-center'>
            <div className="blck faqq-2__blck txt-cent">
              <h5>Hotel booking form – <b>24 hours delivery</b> (Even Weekends) </h5>
              <p>WANT YOUR CONFIRMED HOTEL BOOKING FOR VISA DELIVERED WITHIN <b>
                <u>
                  <Link to="#" target="_self">6 HOURS</Link>? </u>
              </b>
              </p>
              <pre>
                <Link className="button-form">
                  <strong><span>What Customers Are Saying About Us</span></strong></Link></pre>
              <pre>Our itineraries are verifiable on airlines website. We take care of cancellations at no cost to you!</pre>
              <p>
                <em>Our services can be used by anyone from anywhere for any country visa!</em>
              </p>
              <pre>
                <Link to="#" target="_self">Need help in filling the order form? Watch this video tutorial</Link></pre>
              <pre>
                <Link className="button-form">Need an earliest visa appointment?</Link>
              </pre>
            </div>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default FlightReservation

const Wrapper = styled.section`
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: #fff;
    .blck{
        h5{
            font-size: 30px;
            font-weight: 500;
            margin-top: 25px;
            margin-bottom: 25px;
        }
        a{
            color:#119f41;
        }
        .button-form {
        background-color: #119f41;
        color: #fff;
        padding: 11px 46px;
        border-radius: 50px;
        border: none;
        text-transform: uppercase;
        display: inline-block;
        min-height: 47px;
        width: 488px;
        }
        pre {
        margin-bottom: 20px;
        line-height: 1.7;
        font-family: 'Montserrat';
        color: #009A9D;
        white-space: normal;
        overflow: visible;
        font-size:17px;
        font-weight:600;
        a{
            text-decoration:none;
            color:#119f41;
        }
        
        
        }
      

    }
    @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
      .blck .button-form {
          width: 100%;
      }
  }
`;