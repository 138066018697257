import React from 'react'
import FillDetails from "../components/TravelDetails7/FillDetails"
import FlightReservation from '../components/TravelDetails7/FlightReservation'
import TravelersDetails from '../components/TravelDetails7/TravelersDetails'
import GeneralInformation from '../components/TravelDetails7/GeneralInformation'
import HotelDetail from '../components/TravelDetails7/HotelDetail'
import InsuranceInformation from '../components/TravelDetails7/InsuranceInformation'
import TravelGuides from "../components/TravelDetails7/TravelGuides"
import SchengenVisa from "../components/TravelDetails7/SchengenVisa"
import VisaApplication from "../components/TravelDetails7/VisaApplication"
import OrderSummary from "../components/TravelDetails7/OrderSummary"
import PaymentMethod from "../components/TravelDetails7/PaymentMethod"




const TravelDetails7 = () => {
    return (
        <>
        <FillDetails />
           <FlightReservation />
            <TravelersDetails />
            <GeneralInformation />
            <HotelDetail />
            <InsuranceInformation />
            <TravelGuides />
            <SchengenVisa />
            <VisaApplication />
            <OrderSummary />
            <PaymentMethod />
           


        </>
    )
}

export default TravelDetails7