import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Global.css';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import { Home, AdminDashborad, TravelDetails, ContactUs, Faq, TravelDetails2, TravelDetails3, TravelDetails4, TravelDetails5, BookAppointment, Blog, TravelDetails6, TravelDetails7 ,TravelDetails8} from './pages'
import { Header, Footer } from './components'
import ThemeProvider from './ThemeProvider'; 



function App() {
  return (
    <ThemeProvider>
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path='/admindashborad' exact element={<AdminDashborad />} />
        <Route path='/traveldetails' exact element={<TravelDetails />} />
        <Route path='/contact' exact element={<ContactUs />} />
        <Route path='/faq' exact element={<Faq />} />
        <Route path='/TravelDetails2' exact element={<TravelDetails2 />} />
        <Route path='/TravelDetails3' exact element={<TravelDetails3 />} />
        <Route path='/TravelDetails4' exact element={<TravelDetails4 />} />
        <Route path='/TravelDetails5' exact element={<TravelDetails5 />} />
        <Route path='/TravelDetails6' exact element={<TravelDetails6 />} />
        <Route path='/TravelDetails7' exact element={<TravelDetails7 />} />
        <Route path='/TravelDetails8' exact element={<TravelDetails8 />} />
        <Route path='/BookAppointment' exact element={<BookAppointment />} />
        <Route path='/Blog' exact element={<Blog />} />
        
      </Routes>
      <Footer />
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
