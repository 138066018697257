import React from 'react'
import { Container, Row, Col, } from 'react-bootstrap'
import styled from 'styled-components';
import Slider from "react-slick";
import grp from '../../assets/images/grp.png'



const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };
  return (
    <Wrapper className='testi'>
      <Container>
        <Row>
          <Col sm={12}>
            <div class="Experienced">
              <p class="Testimonial">TESTIMONIAL</p>
              <h1 class="client"> What Client Think About Customer. <p></p>
              </h1></div>
          </Col>
          <Container>
            <Row>
              <Slider {...settings}>
                <Col sm={4}>
                  <div className="item">
                    <center>
                      <img class="sam" src={grp} />
                      <p class="costacount">Cost Accounting is enemy number one of productivity</p>
                      <span class="green">Jessica Smith - Amazon co. </span>
                      <p class="read"> People who build their own home tend to be very courageous. These people are curious about life. They're thinking about what it means to live in a house, rather than just buying a commodity and making it work.</p>
                    </center>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="item">
                    <center>
                      <img class="sam" src={grp} />
                      <p class="costacount">Cost Accounting is enemy number one of productivity</p>
                      <span class="green">Jessica Smith - Amazon co. </span>
                      <p class="read"> People who build their own home tend to be very courageous. These people are curious about life. They're thinking about what it means to live in a house, rather than just buying a commodity and making it work.</p>
                    </center>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="item">
                    <center>
                      <img class="sam" src={grp} />
                      <p class="costacount">Cost Accounting is enemy number one of productivity</p>
                      <span class="green">Jessica Smith - Amazon co. </span>
                      <p class="read"> People who build their own home tend to be very courageous. These people are curious about life. They're thinking about what it means to live in a house, rather than just buying a commodity and making it work.</p>
                    </center>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="item">
                    <center>
                      <img class="sam" src={grp} />
                      <p class="costacount">Cost Accounting is enemy number one of productivity</p>
                      <span class="green">Jessica Smith - Amazon co. </span>
                      <p class="read"> People who build their own home tend to be very courageous. These people are curious about life. They're thinking about what it means to live in a house, rather than just buying a commodity and making it work.</p>
                    </center>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="item">
                    <center>
                      <img class="sam" src={grp} />
                      <p class="costacount">Cost Accounting is enemy number one of productivity</p>
                      <span class="green">Jessica Smith - Amazon co. </span>
                      <p class="read"> People who build their own home tend to be very courageous. These people are curious about life. They're thinking about what it means to live in a house, rather than just buying a commodity and making it work.</p>
                    </center>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="item">
                    <center>
                      <img class="sam" src={grp} />
                      <p class="costacount">Cost Accounting is enemy number one of productivity</p>
                      <span class="green">Jessica Smith - Amazon co. </span>
                      <p class="read"> People who build their own home tend to be very courageous. These people are curious about life. They're thinking about what it means to live in a house, rather than just buying a commodity and making it work.</p>
                    </center>
                  </div>
                </Col>
              </Slider>
            </Row>
          </Container>

        </Row>
      </Container>
    </Wrapper>
  )
}

export default Testimonial


const Wrapper = styled.section`
padding:60px 0 25px;
.Experienced{margin:0 0 60px}
.Testimonial {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
    color: #050505;
}
.slick-prev, .slick-next {
  display:none!important;
}
.slick-dots {
  position: unset!important;
  bottom: -25px;
  display: block;
  width: auto!important;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.client {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;
    color: #050505;
}
.item {
    padding: 35px 5px;
    }
  center {
    width: 100%;
    padding: 2px 30px 40px;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    border-radius: 26px;
   
    .sam {
    width: 65px;
    height: 66.04px;
    border-radius: 50%;
    margin: -35px 0 0;
   }
  .costacount {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin: 0;
    padding: 10px 0px 0px 0px;
}
.green {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    color: #079561;
}
.read {
    font-size: 14px;
    line-height: 23px;
    font-weight: 500;
    padding: 15px 0px 0px 0px;
}

}
.slick-dots{
  button:before {
  font-family: 'slick';
  font-size: 20px;
}

.slick-active button:before {
  opacity: 1;
  color: #2d9e63;
}



        @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
         
        
        .slick-dots{
          button:before {
          font-family: 'slick';
          font-size: 20px;
        }
        .slick-active button:before {
          opacity: 1;
          color: #2d9e63;
      }
      }

`;