import React from 'react'
import { Col, Container,Row } from 'react-bootstrap'
import styled from 'styled-components'
import iconone from '../../assets/images/why-1.svg'
import icontwo from '../../assets/images/why-2.svg'
import iconthree from '../../assets/images/why-3.svg'
import iconfour from '../../assets/images/why-4.svg'
import iconfive from '../../assets/images/why-5.svg'
import iconsix from '../../assets/images/why-6.svg'
import iconseven from '../../assets/images/why-7.svg'
import iconeight from '../../assets/images/why-8.svg'

const WhyChoose = () => {
  return (
    <Wrapper className='why-choose'>
    <Container>
        <Row>
            <Col sm={12}>
                <h2>WHY CHOOSE</h2>
            </Col>
            <Col sm="3" className='pb-5'>
                <div className='icon-row'>
                    <img src={iconone} />
                </div>
                <h3>All our itineraries are verifiable</h3>
                <p>All our itineraries are authentic and can be verified. Perfect Flight Reservation for Visa Application! After your VISA is approved, purchase your travel tickets from us and get refund your itinerary costs completely.</p>
            </Col>


            <Col sm="3" className='pb-5'>
                <div className='icon-row'>
                    <img src={icontwo} />
                </div>
                <h3>Support for any country visa</h3>
                <p>Offering personalized advice and guidance to individuals based on their specific travel plans and visa requirements for their chosen destination country.</p>
            </Col>


            <Col sm="3" className='pb-5'>
                <div className='icon-row'>
                    <img src={iconthree} />
                </div>
                <h3>24/7 customer support</h3>
                <p>We provide round-the-clock support, which demonstrates our commitment to assisting clients at any time of the day or night, regardless of their geographical location and time zone. </p>
            </Col>


            <Col sm="3" className='pb-5'>
                <div className='icon-row'>
                    <img src={iconfour} />
                </div>
                <h3>We offer group traveler discounts</h3>
                <p>We provide group discounts that give you the opportunity to save money on visa assistance and other travel-related services. This can be especially attractive for budget-conscious travelers or those planning large group trips.</p>
            </Col>

            <Col sm="3">
                <div className='icon-row'>
                    <img src={iconfive} />
                </div>
                <h3>100% buyer protection-PayPal</h3>
                <p>Buyer protection is a service provided by PayPal to help protect buyers from fraudulent or unauthorized transactions, as well as from items that are significantly different from what was described by the seller.</p>
            </Col>


            <Col sm="3">
                <div className='icon-row'>
                    <img src={iconsix} />
                </div>
                <h3>Online chat, email, and phone</h3>
                <p>We offer multiple support channels, such as online chat, email, and phone support, that significantly enhance your experience and make it easier to reach out for assistance.</p>
            </Col>


            <Col sm="3">
                <div className='icon-row'>
                    <img src={iconseven} />
                </div>
                <h3>Refund available if visa is denied.
                 Refer Refund Policy</h3>
                <p>If you received information stating that if your application is rejected, a refund will be issued, then it means that the entity or organization processing your application has a refund policy in place for such cases.</p>
            </Col>


            <Col sm="3">
                <div className='icon-row'>
                    <img src={iconeight} />
                </div>
                <h3>Affordable pricing and reliable service</h3>
                <p>Offering a portfolio of affordable and reliable services is a significant advantage for any business. It can help attract a broader customer base and build long-term relationships with satisfied clients </p>
            </Col>


            


        </Row>
    </Container>
</Wrapper>
  )
}

export default WhyChoose


const Wrapper = styled.section`
   text-align:center;
   padding:30px 0;
    h2{
        padding-bottom:40px;
    }
        .icon-row {
            width: 56px;
            height: 56px;
            background: #CEF2E9;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            margin: 0 auto 20px;
        }
        @media (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
            .col-sm-3 {
                flex: 0 0 auto;
                width: 50%;
            }
        }
        @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
            .col-sm-3 {
                flex: 0 0 auto;
                width: 100%;
            }
        }
`;