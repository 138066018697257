import React from 'react'
import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';


const Appointment = () => {
    return (
        <Wrapper>
            <Container>

                <div className='travel-input'>
                    <h2 className="text-center mb-4">Appointment</h2>
                    <Accordion defaultKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>  When the appointment is not available at the consulate I wish to apply at. How can you help me?
                            </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <span>We can use our resources and expertise to help you with appointments at other applicable consulates. Please contact us if you need assistance. We are available 24/7 on chat, phone or email.</span>
                                    </p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header> Can I book an appointment with the consulate myself?  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <span>Yes. You can search resources online and make an appointment with the consulate FREE of cost. </span>
                                    </p>
                                    <p>
                                        <span>By using our appointment services and paying a small service fee, you can save your valuable time as you don’t have to go through the daunting process of finding and making the appointment at the right consulate yourself.</span>
                                    </p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>  Which countries and visas can you make visa appointment for? </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <span>We have support for booking Schengen visa appointments in more than 60 countries!</span>
                                    </p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>  How does the visa appointment process work?  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <span>1) You provide your appointment preferences and basic personal details such as name, residence address, email, phone no, passport details etc</span></p><p><span>2) Pay the service fee</span>
                                    </p>
                                    <p>
                                        <span>3) We will send you the order confirmation and visa appointment confirmation to your email</span>
                                    </p>
                                    <p>
                                        <span>To book your visa appointment, please <Link to="#">click here</Link>
                                        </span>
                                    </p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>  How soon can I expect to receive the appointment confirmation from the consulate?  </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    <span>As soon as we receive the appointment order, the support team will work very closely with you to get you the confirmation or an update on your situation within 24 hours. </span>
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header> How can I verify my visa appointment?  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <span>You can verify the appointment by emailing the consulate or by calling the consulate or by checking online on the consulate website as applicable.</span>
                                    </p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header> What is your refund policy for booking visa appointments?  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>What you pay is the service fee to book visa appointments for you. The service fee is fully refundable only for the below conditions.The appointment service fee is non-refundable in all other circumstances not listed below:</p>

                                    <p>1) If we can’t book an appointment for you<br />2) OR, If we receive a service cancellation request from you before the visa appointment has been made</p>

                                    <p>
                                        <span>Submit <Link to="#">
                                            <span>
                                                <strong>REFUND REQUEST</strong>
                                            </span>
                                        </Link> using this link<span>
                                                <strong>within 60 days of the service delivery</strong>
                                            </span>. Please refer to the up to date<Link to="#">refund policy</Link>
                                        </span>
                                    </p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header> Can I book multiple appointments at the same consulate on different dates?   </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <span>No. You can only book one appointment at a consulate at a time.</span>
                                    </p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header> We are a family or group of friends traveling together. Can we book appointments at the consulate for all of them on the same day at the same time?   </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <span>No.</span>
                                    </p>
                                    <p>
                                        <span>As the consulates are very busy, it will be difficult to make appointments for everyone on the same day or at the same time. Most likely you should be open to applying at different applicable consulates on different dates to make sure you get visas on time for your trip. We suggest you plan in advance to avoid any issues.</span>
                                    </p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9">
                            <Accordion.Header> Can I place an appointment order with you for multiple travelers?  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <span>Yes, you can. </span>
                                    </p>
                                    <p>
                                        <span>For ex: If you are a group of 3 then you will have to place one appointment order and choose no of travelers as 3. You will also get group discounts.</span></p>
                                    <p>
                                        <span>We can only make ONE appointment for each of the traveler at the consulate. You should be open to applying at other applicable consulates on different dates to make sure you get visas on time for your trip.</span>
                                    </p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Container>


        </Wrapper>
    )
}

export default Appointment
const Wrapper = styled.section`
font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
.travel-input {
    padding: 50px;
    background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    .accordion-button {
        font-size: 16px;
        font-weight: 500;
       
    }
}
@media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding:30px 0px;
}
@media (max-width: ${({ theme }) => theme.breakpoints.small}) { 
    .travel-input {
   padding: 15px;
   
}
}
`;