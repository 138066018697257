import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import styled from 'styled-components';
import LogoImage from '../assets/images/Logo.svg'
import { Link } from 'react-router-dom';
import TopBar from './Home/TopBar';
const Header = () => {
  return (
    <Wrapper className='navbar'>
    <TopBar/>
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/"><img src={LogoImage}/></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
          <Nav.Item>
             <Link to="/" className="nav-link">PRICING</Link>
          </Nav.Item>
          <Nav.Item>
             <Link to="#" className="nav-link">SERVICES</Link>
          </Nav.Item>
          <Nav.Item>
             <Link to="#" className="nav-link">INSURANCE</Link>
          </Nav.Item>
          <Nav.Item>
             <Link to="/bookappointment" className="nav-link">BOOK APPOINTMENT</Link>
          </Nav.Item>
          <Nav.Item>
             <Link to="#" className="nav-link">BLOG</Link>
          </Nav.Item>
          <Nav.Item>
             <Link to="/faq" className="nav-link">FAQ</Link>
          </Nav.Item>
          <Nav.Item>
             <Link to="/contact" className="nav-link">CONTACT</Link>
          </Nav.Item>
          <Nav.Item>
             <Link to="https://www.facebook.com/VisaReservations/posts/pfbid037Dyx3LznvWYWrH2qEX4CwCYTkZsgBVgP75DW669FYXup7eQwdJjficuge5WYwpxcl" className="nav-link">REVIEWS</Link>
          </Nav.Item>
          <Nav.Item>
             <Link to="https://www.youtube.com/channel/UCU4liGl1HMM97UK5H98g0_g" className="nav-link">SUBSCRIBE</Link>
          </Nav.Item>
          <Nav.Item>
             <Link to="https://www.facebook.com/groups/SchengenVisaCommunity" className="nav-link">ASK COMMUNITY</Link>
          </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
</Wrapper>
  )
}

export default Header

const Wrapper = styled.section`
  width:100%;
  padding:0px;
  margin:0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px;
  .navbar{
    width:100%;
    background: #fff !important;
      .nav-link{
        font-size:13px;
        font-weight:500;
        padding: 0px 8px;
        color:#1C1C1C;
       
      }
      .nav-link:last-child{padding-right:10px;}
  }
 


  @media (max-width: ${({ theme }) => theme.breakpoints.large}){
  div#navbarScroll {
    position: absolute;
    top: 100%;
    background: white;
    z-index: 9;
    width: 100%;
    left: 0;
}
 .navbar .nav-link {
  font-size: 13px;
  font-weight: 500;
  padding: 8px 15px;
  color: #1C1C1C;
}
}
`;