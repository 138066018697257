import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'

const EassySteps = () => {
    return (
        <Wrapper className='eassy-step'>
            <Container>
                <Row>
                    <Col sm={12}>
                        <h2>THREE EASY STEPS</h2>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <div className='main-step'>
                            <div className='line'></div>
                            <div className='step-form'>
                                <div className='paln1'>
                                    <span className='first-step'>1</span>
                                    <h3>Select Plan</h3>
                                    <p>Choose a package that suits your needs. We offer 24 hours and 6 hours email delivery.</p>
                                </div>
                                <div className='paln2'>
                                    <span className='second-step'>2</span>
                                    <h3>Submit Travel Details & Pay</h3>
                                    <p>Provide trip details. Make only service fee payment through PayPal, Debit or Credit card.</p>
                                </div>
                                <div className='paln3'>
                                    <span className='third-step'>3</span>
                                    <h3>Receive Itinerary in Email</h3>
                                    <p>After you get the visa, make your own travel plans and we take care of reservations cancellations for free!</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
        </Wrapper>
    )
}

export default EassySteps


const Wrapper = styled.section`
   background:#F7F7F7;
   padding:60px 0;
        h2{
            text-align:center;
            padding:0 0 40px;
        }
        .main-step{
            max-width: 100%;
            margin: 0 auto;
            position:relative;
            h3{
                margin-top:20px;
                text-align: center;
            }
            p{
                padding: 10px;
            }
            .line{
                width: 66%;
                height: 2px;
                background: #CEF2E9;
                position: absolute;
                top: 38px;
                z-index: 0;
                margin: 0 auto;
                left: 50%;
                transform: translate(-50%,-50%);
            }
            .step-form{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    text-align:center;
                    position:relative;
                    z-index:1;
                    .paln1, .paln3{
                       
                    .first-step, .third-step{
                        background: #CEF2E9;
                        width:56px;
                        height:56px;
                        font-size:24px;
                        color:#079561;
                        font-weight:700;
                        border-radius:60px;
                        line-height:60px;
                        display:block;
                        text-align:center;
                        margin:0 auto;
                       
                    }
                    }
                    .paln2{
                    .second-step{
                        background: #CEF2E9;
                        width:65px;
                        height:65px;
                        font-size:24px;
                        color:#079561;
                        font-weight:700;
                        border-radius:60px;
                        line-height:65px;
                        display:block;
                        margin:0 auto;
                        
                    }
                }
            }
        }

        @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
            padding: 30px 0;
            .container{

            
        .step-form {
            display: block;
            p{
                text-align:center;
            }
        }
        .line {
            display: none;
        }
       

    }
}
`;