import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Form } from 'react-bootstrap'
import { styled } from 'styled-components'
import Santorni from '../../assets/images/Santorini.jpg'




const TravelGuides = () => {
    return (
        <Wrapper>
            <Container>
                <div className='travel-input'>
                    <Row>
                        <h2 className="text-center mb-4">Travel Guides <p className="text-post">Support your justification for the purpose of visit for visa using these detailed travel guides</p></h2>
                        <Col md={12}>
                            <div className="img-travel">
                                <img src={Santorni} />
                                <Link to="#" target="_self">
                                    <strong>(Download a preview of sample travel guide)</strong>
                                </Link>
                            </div>
                            <Form.Label>Do You Need A Travel Medical Insurance As Well?</Form.Label>
                            <div className="gfield_description">Insurance is mandatory for Schengen visa. We will get you the best insurance($0 deductible, $50,000 coverage) out there for your visa application needs.</div>
                            <Form>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3 mt-2">
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-1`}
                                        />
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-2`}
                                        />
                                    </div>
                                ))}
                            </Form>
                            <div className="gfield_description">(Most common reason for visa rejection is "Justification for the purpose and the condition of the intended stay was not provided". Use these travel guides to create a powerful cover story and how you plan to spend your time during the trip)</div>
                        </Col>
                        <Form.Group as={Col} md="6" className='mb-4 tp1 ' controlId="validationCustomUsername">
                            <Form.Label className='mb-2 mt-3'>No Of Cities*</Form.Label>
                            <Form.Select aria-label="Default select example">
                                <option value="">Please Select</option>
                                <option value="1">1</option>
                                <option value="1.8">2</option>
                                <option value="2.55">3</option>
                                <option value="3.40">4</option>
                                <option value="4.25">5</option>
                                <option value="4.8">6</option>
                                <option value="5.25">7 and more</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                </div>
            </Container>
        </Wrapper>
    )
}
export default TravelGuides
const Wrapper = styled.section`
   font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
    .travel-input {
	padding: 50px;
	background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    .img-travel img {
    display: block;
    max-width: 87px;
    width: 50px;
   
    .tp1 {
        padding-top: 10px;
    }
  
}
.img-travel {
    margin-bottom: 5px;
    a{
    text-decoration:none;
   }
    }
    
}
@media (max-width: ${({ theme }) => theme.breakpoints.medium}) { 
    .travel-input {
   padding: 15px;
   
}
}


`;