import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import blogImageone from '../../assets/images/img001.png'
import blogImagetwo from '../../assets/images/img002.png'
import blogImagethree from '../../assets/images/img003.png'
import blogImagefour from '../../assets/images/img004.png'
import { Link } from 'react-router-dom'

const Blog = () => {
    return (
        <Wrapper className='blog'>
            <Container>
                <Row>
                    <Col sm={6}>
                        <div className="inffo__cell">
                            <img src={blogImageone} />
                            <div className="inffo__cell-cont">
                                <p>
                                    <b>Don’t Buy Flight Tickets</b>
                                </p>
                                <div className="flight-line"></div>
                                <p>The Embassy recommends not to purchase fully paid <br /> flight tickets until the visa is approved <br />

                                </p>
                                <Link to="#" target="_self">See why…</Link>
                            </div>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className="inffo__cell">
                            <img src={blogImagetwo} />
                            <div className="inffo__cell-cont">
                                <p>
                                    <b>Order Reservations</b>
                                </p>
                                <div className="flight-line"></div>
                                <p>Pay only service fee for reservations. We will take care<br />
                                    of cancellations at no additional cost to you<br />

                                </p>
                                <Link to="#" target="_self">See why…</Link>
                            </div>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className="inffo__cell">
                            <img src={blogImagethree} />
                            <div className="inffo__cell-cont">
                                <p>
                                    <b>Get Visa</b>
                                </p>
                                <div className="flight-line"></div>
                                <p>The Embassy accepts itinerary as it shows<br />
                                    true intent of travel

                                </p>
                                <Link to="#" target="_self">See why…</Link>
                            </div>
                        </div>

                    </Col>

                    <Col sm={6}>
                        <div className="inffo__cell">
                            <img src={blogImagefour} />
                            <div className="inffo__cell-cont">
                                <p>
                                    <b>Plan Trip</b>
                                </p>
                                <div className="flight-line"></div>
                                <p>After the visa is approved, you can then make<br />
                                    your own travel plans!

                                </p>
                                <Link to="#" target="_self">See why…</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    )
}

export default Blog


const Wrapper = styled.section`
    background: #f7f7f7;
    padding: 60px 0px;
    .col-sm-6{
        &:nth-child(3n) .inffo__cell {
            margin-bottom: 30;
            padding:0;
        }

        &:last-child .inffo__cell {
        margin-bottom: 30;
        padding: 0;
    }
    }
    .inffo__cell {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 30px;
        position:relative;
        height:300px;
        &:before{   
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            right: 0;
            top: 0px;
            background: -moz-linear-gradient(top,  #ff8227 0%, #079561 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  #ff8227 0%,#079561 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  #ff8227 0%,#079561 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 0.7;
            width:100%;
            height:100%;
                
        }
        .flight-line {
            border: 1px solid white;
            width: 141px;
            position: relative;
            top: 0;
            margin: 10px 0;
         }
        img{width:100%; height:300px;}
        .inffo__cell-cont {
            position: absolute;
            z-index: 1;
            bottom: 12px;
            left: 31px;
            a{
                font-size: 14px;
                font-weight: 400;
                line-height: 35px;
                color: #fff;
                text-decoration: none;
            }
            p{
                font-size: 17px;
                font-weight: 400;
                line-height: 26px;
                color:#fff;
                margin: 0;
            b{
                font-size: 22px;
                font-weight: 700;
                line-height: 14px;
            }
            a{
                font-size: 14px;
                font-weight: 400;
                line-height: 35px;
                color: #fff;
                text-decoration: none;
            }
            
        }
    }

   
 }
 @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
  padding:0;
 }
`;