import React from 'react'
import  FillDetails  from "../components/TravelDetails/FillDetails";
import FlightReservation from '../components/TravelDetails/FlightReservation'
import TravelersDetails from '../components/TravelDetails/TravelersDetails'
import  GeneralInformation  from '../components/TravelDetails/GeneralInformation'
import HotelDetail  from '../components/TravelDetails/HotelDetail'
import InsuranceInformation from '../components/TravelDetails/InsuranceInformation'
import  TravelGuides  from "../components/TravelDetails/TravelGuides"
import SchengenVisa from "../components/TravelDetails/SchengenVisa"
import VisaApplication from "../components/TravelDetails/VisaApplication"
import OrderSummary  from "../components/TravelDetails/OrderSummary"
import PaymentMethod from "../components/TravelDetails/PaymentMethod";



const TravelDetails = () => {
  return (
    <>
    <FillDetails/>
    <FlightReservation/>
    <TravelersDetails/>
    <GeneralInformation/>
    <HotelDetail/>
    <InsuranceInformation/>
    <TravelGuides/>
    <SchengenVisa/>
    <VisaApplication/>
    <OrderSummary/>
    <PaymentMethod/>
    </>
  )
}

export default TravelDetails