import React from 'react'
import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from 'react-bootstrap/Accordion';
import { styled } from 'styled-components';


const Specific = () => {
    return (
        <Wrapper>
            <Container>

                <div className='travel-input'>
                    <h2 className="text-center mb-4">Specific</h2>
                    <Accordion defaultKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header> I have submitted the order details and made the payment but I didn’t get any confirmation. What should I do? </Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>As soon as you submit the order and make the payment, you should receive both the confirmation email and the payment receipt. If you didn’t receive any of these then it is highly likely that the EMAIL ID you typed is incorrect. Please reach out to us immediately either by online chat, phone or send email to admin@visareservation.com. We will advise as appropriate.</p>
                                 </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>  What do you mean by “Free Cancellations” and “No Obligations”? </Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>What you pay is only the service fee to make the reservations for you. We take care of reservations cancellations that we made on your behalf at NO ADDITIONAL cost to you as opposed to the airlines charging an additional cancellation fee when you book and cancel a flight reservation – that’s what we mean by “Free Cancellations”!</p>
                                
                                <p>You are also not obligated to purchase these reservations from us and you can make your own travel plans once the visa is approved – that’s what we mean by “No Obligation”!</p> 
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>  I’m applying for Schengen visa at Italian Consulate in San Francisco, California. Can I use your services ?  </Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>For flights and hotels requirements – <strong>NO, we can’t assist you</strong></p>
                                
                                <p>For Insurance requirements – <strong>YES, we can assist you</strong></p>
                                
                                <p>Italian consulate in San Francisco accepts only e-tickets that are fully paid for. <span><strong>They do not accept flight reservations or hotel reservations unlike most of the other Schengen embassies</strong></span>.</p>
                                <p>Hence, we suggest you to:</p>
                                
                                <p>a) Purchase refundable flight tickets directly with the airlines to meet their requirement. Especially, the thirteen-digit e-ticket number must be visible and legible on the flight tickets</p>
                                
                                <p>b) Book hotel rooms directly with the hotels and get the confirmation letter directly from the hotel to meet their requirements</p><p>We provide flight itinerary and not fully paid tickets(e-tickets) as part of our services.</p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>  I’m applying for Schengen Visa from UK. Can I use your services? </Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>If you are applying for Schengen Visa from the UK, very few embassies require you to submit fully paid tickets for the visa application and not the flight reservations. We provide flight itinerary and not fully paid tickets as part of our services. Kindly double check the embassy requirements within your jurisdiction.</p>
                                 </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header> I want to visit multiple cities. Is there a limit on flight and hotel reservations that i can make?  </Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>You are more than welcome to order unlimited flights and hotel reservations per complete trip. For multiple round trips, you have to place separate orders though. All the packages by default include upto 3 hotels. You can, however, add additional hotels for $10/hotel. All the hotel bookings come up with maximum 3 weeks of stay per hotel.</p> </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>  My friends and family are applying for a visa. Can i submit an order to make flight and hotel reservations on their behalf? </Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>Absolutely. You can place an order on behalf of your friends, family or anyone. All you have to do is choose the right package, submit travel details and make the payment using the authorized payment source(Card or PayPal account).</p>
                                 </div>
                               
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>  We are a group of friends or a family traveling together. Can we use your service to place order for all of them at once?  </Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>Yes! you have the option to place an order for all the travelers at once. We can create one itinerary for all the travelers or separate itineraries per person. You just let us know your preference and we will be at your service!</p>
                                 </div>
                               
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>  Do you print traveler name on the itineraries?  </Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>Yes. The itinerary we provide will have traveler names printed on it.</p> 
                                </div>
                               
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>  We are a group of travelers. Do you offer any group discounts?  </Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>Yes, we offer excellent group discount offers. When you select an appropriate number of travelers while filling the order form, you will see the discounted pricing. All we want is you to relax and get the visa. Once you get the visa you can make your travel plans and enjoy a fun-filled trip with your friends or family.</p> 
                                </div>
                               
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9">
                            <Accordion.Header>  How soon can you provide flight and hotel reservations once i make the payment?  </Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>It depends on the service option you choose. We have the support to email you the documents within 1 hr or 6 hrs or 24 hrs. Our team is also available 24/7 by email, phone, and online chat to assist you!</p> 
                                </div>
                               
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Container>


        </Wrapper>
    )
}

export default Specific
const Wrapper = styled.section`
font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
.travel-input {
    padding: 50px;
    background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    .accordion-button {
        font-size: 16px;
        font-weight: 500;
       
    }
}
@media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding:30px 0px;
}
@media (max-width: ${({ theme }) => theme.breakpoints.small}) { 
    .travel-input {
   padding: 15px;
   
}
}
`;