import React from 'react'
import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from 'react-bootstrap/Accordion';
import { styled } from 'styled-components';


const SchengenVisa1 = () => {
    return (
        <Wrapper>
            <Container>

                <div className='travel-input'>
                    <h2 className="text-center mb-4">Schengen Visa Videos - Part 1</h2>
                    <Accordion defaultKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header> Schengen Visa Overview: Everything You Need To Know  </Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>
                                    <iframe src="https://www.youtube.com/embed/xBm90s2BRD8?rel=0&amp;autohide=1&amp;modestbranding=1&amp;showinfo=0" width="560" height="315" frameborder="0" allowfullscreen="allowfullscreen">
                                        </iframe>
                                        </p> 
                                        </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>  Schengen Countries Area: List You Need To Know To Apply For Schengen Visa  </Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>
                                    <iframe loading="lazy" src="https://www.youtube.com/embed/DBcoFq2j0Uk?rel=0&amp;autohide=1&amp;modestbranding=1&amp;showinfo=0" width="560" height="315" frameborder="0" allowfullscreen="allowfullscreen">
                                        </iframe>
                                        </p> 
                                        </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>  Schengen Visa – Top 10 Easiest Schengen Countries to Apply  </Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>
                                    <iframe loading="lazy" src="https://www.youtube.com/embed/F9tfXgLkgP4?rel=0&amp;autohide=1&amp;modestbranding=1&amp;showinfo=0" width="560" height="315" frameborder="0" allowfullscreen="allowfullscreen">
                                        </iframe>
                                        </p> 
                                        </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>  Schengen Visa Types – Everything You Need To Know  </Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>
                                    <iframe loading="lazy" src="https://www.youtube.com/embed/Ldt4s5McN_g?rel=0&amp;autohide=1&amp;modestbranding=1&amp;showinfo=0" width="560" height="315" frameborder="0" allowfullscreen="allowfullscreen">
                                        </iframe>
                                        </p> 
                                        </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>  Schengen Visa Statistics 2018 – Applications Received and Rejection Rates  </Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>
                                    <iframe loading="lazy" src="https://www.youtube.com/embed/u-GvsaK76Vc?rel=0&amp;autohide=1&amp;modestbranding=1&amp;showinfo=0" width="560" height="315" frameborder="0" allowfullscreen="allowfullscreen">
                                        </iframe>
                                        </p> 
                                        </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>  Schengen Visa Fees – Everything You Need to Know  </Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>
                                    <iframe loading="lazy" src="https://www.youtube.com/embed/xDqu43lZFEw?rel=0&amp;autohide=1&amp;modestbranding=1&amp;showinfo=0" width="560" height="315" frameborder="0" allowfullscreen="allowfullscreen">
                                        </iframe>
                                        </p> 
                                        </div>
                               
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Schengen Visa – Eligibility, Categories, Purpose and Who Can Apply</Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>
                                    <iframe loading="lazy" src="https://www.youtube.com/embed/fuY6sUieWrI?rel=0&amp;autohide=1&amp;modestbranding=1&amp;showinfo=0" width="560" height="315" frameborder="0" allowfullscreen="allowfullscreen">
                                        </iframe>
                                        </p> 
                                        </div>
                               
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header> Schengen Visa – 5 Easy Steps to Apply </Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>
                                    <iframe loading="lazy" src="https://www.youtube.com/embed/-fWU_nvYQX8?rel=0&amp;autohide=1&amp;modestbranding=1&amp;showinfo=0" width="560" height="315" frameborder="0" allowfullscreen="allowfullscreen">
                                        </iframe>
                                        </p>
                                         </div>
                               
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>Schengen Visa Application Requirements</Accordion.Header>
                            <Accordion.Body>
                            <div> 
                                <p>
                                    <iframe loading="lazy" src="https://www.youtube.com/embed/j6ul8prXqag?rel=0&amp;autohide=1&amp;modestbranding=1&amp;showinfo=0" width="560" height="315" frameborder="0" allowfullscreen="allowfullscreen">
                                        </iframe>
                                        </p>
                                         </div>
                               
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Container>


        </Wrapper>
    )
}

export default SchengenVisa1
const Wrapper = styled.section`
font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
.travel-input {
    padding: 50px;
    background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    .accordion-button {
        font-size: 16px;
        font-weight: 500;
       
    }
}
iframe {
    border: 0;
    width: 100%;
}
@media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding:30px 0px;
}
@media (max-width: ${({ theme }) => theme.breakpoints.small}) { 
    .travel-input {
   padding: 15px;
   
}
}
`;