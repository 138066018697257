import React from 'react'
import { Container, Row, Form, Button, InputGroup } from 'react-bootstrap'
import { styled } from 'styled-components'
import jumbo2 from "../../assets/images/jumbo2.jpg";
import { FaMagnifyingGlass } from "react-icons/fa6";


const FaqBanner = () => {
    return (
        <Wrapper className="jumbo">

            <div className="jumbo-2__txt">
                <h2>Frequently asked questions</h2>
                <p>Get answers to the most popular questions.</p>
                <center>
                <InputGroup className="mb-3 serach-bar">
                <InputGroup.Text><FaMagnifyingGlass/></InputGroup.Text>
                    
                    <Form.Control
                        aria-label="Example text with button addon"
                        aria-describedby="basic-addon1"
                        placeholder="Search"
                    />
                    <Button variant="outline-secondary" id="button-addon1">
                        Search
                    </Button>
                </InputGroup>
                </center>
            </div>

        </Wrapper>
    )
}


export default FaqBanner
const Wrapper = styled.section`
    background:url(${jumbo2});
    min-height: 450px;
    background-repeat: no-repeat;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    background-position: center;
    &:after{
        content: "";
    position: absolute;
    left: -1px;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    }
    h2{
        color: #fff;
        font-size: 40px;
        font-weight: 400;
        line-height: 60px;
  
    }
    .jumbo-2__txt {
        position: relative;
        z-index: 2;
        color: #fff;
        text-align: center;
        width: 100%;
        max-width:1100px;
        p {
        font-size: 18px;
        color: #ffffff;
        font-weight: 400;
        line-height: 25px;
    }
    button{
        color:#fff;
        width: 100px;
    }
    .serach-bar{
        width:50%;
    }
    .input-group-text {
        background: #eee;
    }
   
   
}

@media (max-width: ${({ theme }) => theme.breakpoints.small}) {
     .jumbo-2__txt .serach-bar {
        width: 80%;
    }
}        
    `;

