import React from 'react'
import FillDetails from "../components/TravelDetails6/FillDetails"
import FlightReservation from '../components/TravelDetails6/FlightReservation'
import TravelersDetails from '../components/TravelDetails6/TravelersDetails'
import GeneralInformation from '../components/TravelDetails6/GeneralInformation'
import FlightDetails from '../components/TravelDetails6/FlightDetails'
import InsuranceInformation from '../components/TravelDetails6/InsuranceInformation'
import TravelGuides from "../components/TravelDetails6/TravelGuides"
import SchengenVisa from "../components/TravelDetails6/SchengenVisa"
import VisaApplication from "../components/TravelDetails6/VisaApplication"
import OrderSummary from "../components/TravelDetails6/OrderSummary"
import PaymentMethod from "../components/TravelDetails6/PaymentMethod";




const TravelDetails6 = () => {
    return (
        <>
        <FillDetails />
           <FlightReservation />
            <TravelersDetails />
            <GeneralInformation />
            <FlightDetails />
            <InsuranceInformation />
            <TravelGuides />
            <SchengenVisa />
            <VisaApplication />
            <OrderSummary />
            <PaymentMethod />
           


        </>
    )
}

export default TravelDetails6