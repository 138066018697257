import React from 'react'
import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from 'react-bootstrap/Accordion';
import { styled } from 'styled-components';


const SchengenVisa2 = () => {
    return (
        <Wrapper>
            <Container>

                <div className='travel-input'>
                    <h2 className="text-center mb-4">Schengen Visa Videos - Part 2</h2>
                    <Accordion defaultKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>  Schengen Visa: How to Write an Invitation Letter for Visa Application  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <iframe loading="lazy" src="https://www.youtube.com/embed/2286LjuF9K4?rel=0&amp;autohide=1&amp;modestbranding=1&amp;showinfo=0" width="560" height="315" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
                                    </p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>  How to Write a Cover Letter for Visa Application – Schengen Visa </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <iframe loading="lazy" src="https://www.youtube.com/embed/vGbL_JLXcGU?rel=0&amp;autohide=1&amp;modestbranding=1&amp;showinfo=0" width="560" height="315" frameborder="0" allowfullscreen="allowfullscreen">
                                        </iframe>
                                    </p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>  Schengen Visa – How to Write a No Objection Letter for Visa Application  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <iframe loading="lazy" src="https://www.youtube.com/embed/G9dj7p_TA88?rel=0&amp;autohide=1&amp;modestbranding=1&amp;showinfo=0" width="560" height="315" frameborder="0" allowfullscreen="allowfullscreen">
                                        </iframe>
                                    </p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Container>

        </Wrapper>
    )
}

export default SchengenVisa2
const Wrapper = styled.section`
font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
.travel-input {
    padding: 50px;
    background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    .accordion-button {
        font-size: 16px;
        font-weight: 500;
       
    }
}
iframe {
    border: 0;
    width: 100%;
}
@media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding:0px 0px 30px 0px;
}
@media (max-width: ${({ theme }) => theme.breakpoints.small}) { 
    .travel-input {
   padding: 15px;
   
}
}
`;