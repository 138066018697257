import React from "react";
import { Container } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import AccordionItem from "react-bootstrap/Accordion";
import { styled } from "styled-components";

const Reservations = () => {
    return (
        <Wrapper>
            <Container>
                <div className="travel-input">
                    <h2 className="text-center mb-4">Reservations</h2>
                    <Accordion defaultKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header> How Visa Reservation Works? </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    <span>
                                        a) You provide your basic travel details for
                                        flights(Departing city, arrival city, departure dates) and
                                        hotels(City, check in and check out dates) and submit the
                                        order and Pay only service fee
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        b) We will then send you reservations in email pdf. You can
                                        use those reservations to get the visa
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        c) After you get the visa, you can make your own travel
                                        plans and purchase anything of your choice online&nbsp;
                                    </span>
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                {" "}
                                How can I verify flights and hotels?{" "}
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>Verification of Flight:</p>
                                <p>
                                    <span>
                                        Flight reservations are verifiable. You can visit respective
                                        airlines website, plugin the airline reservation code and
                                        your name under manage my bookings. If you have any issues,
                                        then please send email to admin@visareservation.com
                                    </span>
                                </p>
                                <p>Verification of Hotel:</p>
                                <p>
                                    <span>
                                        Hotel reservations are verifiable. You can call the hotel
                                        directly to verify using your name. Hotel itinerary has a
                                        confirmation number from the booking systems that indicate
                                        that bookings have been made successfully with the hotel
                                        systems.{" "}
                                    </span>
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                {" "}
                                How long are the reservations and insurance valid for?{" "}
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>Flight and Hotel Validity:</p>
                                <p>
                                    <span>
                                        Typically our flight reservations are valid for two weeks
                                        however sometimes it depends on airlines policies it gets
                                        auto canceled due to overbooking etc.&nbsp;
                                    </span>
                                    <span>
                                        Hotel bookings are valid up to 3 weeks however sometimes
                                        hotel bookings may also get auto canceled due to hotel
                                        cancellation policies and high demand in bookings.&nbsp;
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        Not to worry. We can recreate it immediately if such issue
                                        arises. The embassy will not refuse visa due to this they
                                        will request you to re-submit the reservation but again
                                        that’s very rare.
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        If you want us to extend the hotel bookings beyond&nbsp;3
                                        weeks then pls contact us and let us know. We will be happy
                                        to extend the hotel bookings contingent to hotels policies
                                        allowing us to do so.
                                    </span>
                                </p>
                                <p>Insurance Validity:</p>
                                <p>
                                    <span>
                                        The insurance we provide is a valid one and can be used
                                        during your travel and stay. It meets all the Schengen visa
                                        requirements and is valid across all Schengen countries and
                                        international too. The signed insurance visa letter you
                                        receive from us is perfect for visa purposes.
                                    </span>
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                {" "}
                                I would like to see a sample flight and hotel reservation before
                                placing an order. Can you pls provide one?{" "}
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Of course. Please send us an email or chat with us. We will be
                                    happy to provide one.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                {" "}
                                My visa interview is coming up soon. Can you provide me flight
                                and hotel reservation urgently?{" "}
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    Yes, we have support to provide you reservations within 1 hr
                                    or 6hrs or 24 hrs. Just choose the right package and place the
                                    order. Our team is also available 24/7 over email, phone and
                                    online chat to serve you! If you need itinerary on
                                    demand(immediately) then you can place the order using online
                                    chat and we will email you the itinerary within minutes!
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>
                                {" "}
                                What details do you need from me to place an order for flight
                                and hotel reservation?{" "}
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    We don’t ask for any confidential information. All we need is
                                </p>

                                <p>1) Your Full Name</p>
                                <p>
                                    <span>
                                        2) Your basic travel details for flights(Departing city,
                                        arrival city, departure dates) and hotels(City, check in and
                                        check out dates)
                                    </span>
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Container>
        </Wrapper>
    );
};
export default Reservations;
const Wrapper = styled.section`
  font-weight: 500;
  margin: 0;
  background: #f7f7f7;
  padding: 60px 0px;
  .travel-input {
    padding: 50px;
    background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    .accordion-button {
      font-size: 16px;
      font-weight: 500;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 30px 0px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    .travel-input {
      padding: 15px;
    }
  }
`;
