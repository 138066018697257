import React from 'react'
import { styled } from 'styled-components'
import { Container, Row, Col, Form, Button, FloatingLabel } from 'react-bootstrap';



const OrderSummary = () => {
    return (
        <Wrapper>
            <Container>
                <div className='travel-input'>
                    <Row>
                        <h2 className="text-center mb-4">Order Summary:</h2>
                        <Col md={12}>
                            <ul className='payment-div'>
                                <li>
                                    <label className="gfield_label">Flight + Hotel Itinerary<br />(Upto 3 hotels only. Additional hotels @ $10/hotel. Max stay upto 3 weeks/hotel)</label>

                                    <div className=" product_calculation0">
                                        <span className="ginput_product_price_label">Price:</span>
                                        <span className="ginput_product_price" id="input_87_14">$114.00</span>
                                    </div>

                                </li>
                                <li>
                                    <label className="gfield_label">Visa Interview Questions Guide - Non Refundable</label>
                                    <div className=" product_calculation">
                                        <span className="ginput_product_price_label">Price:</span>
                                        <span className=" ginput_product_price" id="input_87_14">$10.00</span>
                                    </div>
                                </li>

                                <li>
                                    <label className="gfield_label">Visa Package - Non Refundable</label>
                                    <div className=" product_calculation">
                                        <span className="ginput_product_price_label">Price:</span>
                                        <span className=" ginput_product_price" id="input_87_14">$40.00</span>
                                    </div>
                                </li>
                                <li>
                                    <label className="gfield_label">Any Promotional Code?</label>
                                    <div className=" product_calculation2">


                                        <Form.Control type="email" placeholder="Type Code Here" />
                                        <Button variant="success">APPLY</Button>
                                    </div>
                                </li>
                                <li className="last-li">
                                    <label className="gfield_label">Total Payment</label>
                                    <div className="ginput_container product_calculation">
                                        <span className="gform-field-label  ginput_product_price" id="input_87_14">$150.00</span>
                                    </div>
                                </li>

                            </ul>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Wrapper>
    )
}

export default OrderSummary
const Wrapper = styled.section`
    font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
    .travel-input {
	padding: 50px;
	background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    .payment-div li {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-top: 2px dotted #069460;
    border-left: 2px dotted #069460;
    border-right: 2px dotted #069460;
}
.last-li {
    border-bottom: 2px dotted #069460;
}
.ginput_product_price {
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 700;
    color: #069460;
}
.ginput_product_price_label {
    font-size: 20px;
    font-weight: 700;
    margin-right: 10px;
    
}
label {
    display: inline-block;
    line-height: 35px;
    
}
.product_calculation2 {
    display: flex;
    input{
        margin-right:10px;
    }
}
.product_calculation0 {
    line-height: 65px;
}
    
    }
    
    

    @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
        padding:0;
       
            }
           
            @media (max-width: ${({ theme }) => theme.breakpoints.medium}) { 
                .travel-input {
               padding: 15px;
               
           }
        
           .payment-div {
            padding: 0px;
           }
        }
        @media (max-width: ${({ theme }) => theme.breakpoints.small}) { 
        .travel-input .payment-div li {
            display: block;
        }
       
    }
`;