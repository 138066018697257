import React from 'react'
import Banner from '../components/Home/Banner'
import WhatWeOffer from '../components/Home/WhatWeOffer'
import EassySteps from '../components/Home/EassySteps'
import Subscription from '../components/Home/Subscription'
import Blog from '../components/Home/Blog'
import Testimonial from '../components/Home/Testimonial'
import WhyChoose from '../components/Home/WhyChoose'
import CheckVisaCountry from '../components/Home/CheckVisaCountry'

const Home = () => {
  return (
    <>
   <Banner/>
   <WhatWeOffer/>
   <EassySteps/>
   <Subscription/>
   <Blog/>
   <Testimonial/>
   <WhyChoose/>
   <CheckVisaCountry/>
   </>
  )
}

export default Home