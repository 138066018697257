import React from 'react'
import { styled } from 'styled-components'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Form } from 'react-bootstrap'
import Preview from '../../assets/images/Preview.jpg'


const SchengenVisa = () => {
    return (
        <Wrapper>
            <Container>
                <div className='travel-input'>
                    <Row>
                        <h2 className="text-center mb-4">Schengen Visa Interview Questions Guide <p className="text-post">Don't jeopardize your Schengen visa interview at the last minute! Use this thorough visa interview guide to crack the visa interview. Be guided. Be well prepared!</p></h2>
                        <Col md={12}>
                            <div className="img-travel">
                                <img src={Preview} />
                                <Link to="#" target="_self">
                                    <strong>(Preview sample visa interview questions guide)</strong>
                                </Link>
                            </div>


                            <Form.Label>Do you need a Schengen Visa Interview Guide that can help you prepare better for the Embassy interview?</Form.Label>

                            <Form>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3 mt-2">
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-1`}
                                        />
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-2`}
                                        />
                                    </div>
                                ))}
                            </Form>
                            <div class="gfield_description" id="gfield_description_87_404">(The Schengen visa interview is the most difficult part of the application process, and applicants must be well-prepared to answer the questions unique to your case depending on your current occupation and the purpose of your visit)</div>

                        </Col>
                        <Form.Group as={Col} md="12 mt-3" controlId="validationCustomUsername">
                            <Form.Label className='mb-2'>What is your current occupation?*</Form.Label>

                            <Form.Control
                                as="textarea"
                                placeholder="Student, Privately or Government or Self Employed, Unemployed, Retired, Journalist, Medical Tourist etc;"
                                style={{ height: '200px' }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="12 mt-3" controlId="validationCustomUsername">
                            <Form.Label className='mb-2'>What is your purpose of visit?*</Form.Label>

                            <Form.Control
                                as="textarea"
                                placeholder="Tourism, Study, Work, Business, Visit, Medical, Sports, Training, Research, Culture etc;"
                                style={{ height: '200px' }}
                            />
                        </Form.Group>

                    </Row>
                </div>
            </Container>
        </Wrapper>
    )
}

export default SchengenVisa
const Wrapper = styled.section`
  font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
    .travel-input {
	padding: 50px;
	background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    .img-travel img {
    display: block;
    max-width: 87px;
    width: 50px;
    }
    a{
        text-decoration:none;
    }
    .tp1 {
        padding-top: 10px;
    }
    .img-travel {
    margin-bottom: 5px;
    a{
    text-decoration:none;
   }
    }
    }
    
    @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
        padding:0;
            }
    @media (max-width: ${({ theme }) => theme.breakpoints.medium}) { 
        .travel-input {
       padding: 15px;
       
   }
}
`;