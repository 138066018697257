import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { styled } from 'styled-components'
import jumbo from "../../assets/images/jumbo.jpg";


const FillDetails = () => {
    return (
                <Wrapper className="jumbo">
                
                    <div className="jumbo-2__txt">
                        <h2>Fill details – Pay – Get Itinerary Via Email</h2>
                        <p>Embassy recommends not to purchase tickets until visa is approved. So don’t risk your <br />money, time and effort by buying actual tickets. We provide you confirmed flight <br />reservation for visa, hotel booking and travel medical insurance that are perfect for visa <br />application. Once you get your visa, make your own travel plans! </p>
                    </div>
                   
                </Wrapper>


    )
}

export default FillDetails
const Wrapper = styled.section`
    background:url(${jumbo});
    min-height:400px;
    background-repeat: no-repeat;
    position: relative;
    align-items: center;
    justify-content: center;
    display:flex;
    &:after{
        content: "";
    position: absolute;
    left: -1px;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    }
    h2{
      color:#fff;
    font-size: 35px;
    font-weight: 600;
  
    }
    .jumbo-2__txt {
        position: relative;
        z-index: 2;
        color: #fff;
        text-align: center;
        width: 95%;
        p {
        font-size: 14px;
        color: #ffffff;
        font-weight: 400;
        line-height: 25px;
    }
   
   
}

                       
    `;