import { useState } from 'react';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';

import styled from 'styled-components';


const TravelersDetails = () => {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };
    return (
        <Wrapper className='travel-info'>
            <Container>
                <div className='travel-input'>
                    <Row>
                        <Col sm={12}>
                            <h2 className="text-center mb-4">Travelers Details</h2>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">

                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustomUsername">
                                        <Form.Label>No. Of Travelers*</Form.Label>
                                        <Form.Select aria-label="Default select example">

                                            <option value="1" selected="selected">1</option>
                                            <option value="1.8">2</option>
                                            <option value="2.55">3</option>
                                            <option value="3.40">4</option>
                                            <option value="4.25">5</option>
                                            <option value="4.8">6</option>
                                            <option value="5.25">7 and more</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustomUsername">
                                        <Form.Label>Delivery Email Address*</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                type="email"
                                                placeholder="John.rick@gmail.com"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please choose a username.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustom02">
                                        <Form.Label>Phone No.*</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Country Code + Phone Number"
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" className='mb-4' controlId="validationCustom01">
                                        <Form.Label>Traveler 1 First Name (Should match passport)*</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="First name"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                        <Form.Label>Traveler 1 Last Name (Should match passport)*</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Last name"
                                        />
                                    </Form.Group>


                                    <Form.Group as={Col} md="12" controlId="validationCustomUsername">



                                        <div className="gfield_description">Provide Travelers Flight Details:*</div>
                                        <Form.Label>Departure date - Departing city/airport - Arrival city/airport</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Departing on June 1, 2017 from Los Angeles(LAX) to Frankfurt(FRA); Returning on June 10, 2017 from Frankfurt(FRA) to Los Angeles(LAX) or Type &quot;Please see attachment&quot;"
                                            style={{ height: '200px' }}
                                        />
                                    </Form.Group>

                                    <Col sm={12}>
                                        <div className="flight-prize">
                                            <div className="flight-p">Your Flight Price Is: <br />
                                                <div className="gfield_description" id="gfield_description_87_62">(Upto 3 hotels only. Additional hotels @ $10/hotel. Max stay upto 3 weeks/hotel)</div>
                                            </div>
                                            <div className="total-value">$110</div>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Wrapper>
    )
}

export default TravelersDetails
const Wrapper = styled.section`
    font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
 
    .travel-input {
	padding: 50px;
	background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
        .flight-prize {
        display: flex;
        justify-content: space-between;
        font-size: 25px;
        font-weight: 500;
        position: relative;
        }

        .flight-p {
        padding: 7px;
        &: before { content: "";
        position: absolute;
        width: 72%;
        height: 3px;
        top: 30px;
        text-align: center;
        left: 275px;
        border: 2px dotted #069460;
    }
    }
    .total-value {
	text-transform: uppercase;
	font-size: 35px;
	font-weight: 700;
	font-family: "Poppins";
	color: #069460;
}
label{
    margin-bottom:5px;
}
.gfield_description {
    font-size: 14px;
    font-weight: 500;
}
}



@media (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    .flight-p {
        padding: 7px;
        &: before
         {
            display:none;
       
    }
    }
@media (max-width: ${({ theme }) => theme.breakpoints.medium}) { 
    .travel-input {
   padding: 15px;
   
}
}
@media (max-width: ${({ theme }) => theme.breakpoints.small}) { 
    .travel-input {
    .flight-prize {
        font-size:19px;
        display:block;
        .total-value {
            font-size: 35px;
            color: #069460;
            text-align: center;
            background: #bbf3e0;
        }
        
    }
    
}

`;