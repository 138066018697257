import { useState } from 'react';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';



const GeneralInformation = () => {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };
    return (
        <Wrapper>
            <Container>
                <div className='travel-input'>
                    <Row>
                        <Col sm={12}>
                            <h2 className="text-center mb-4">General Information</h2>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" className='mb-4 interview-tp' controlId="validationCustom01">

                                        <div className="gfield_description">Visa Interview Date</div>
                                        <Form.Label>If you don't have a date yet, leave it blank</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="First name"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                                        <Form.Label>What Consulate Are You Applying At?</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Germany"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustomUsername">
                                        <Form.Label>How Did You Hear About Us?</Form.Label>
                                        <Form.Select aria-label="Default select example">

                                            <option>Please Select</option>
                                            <option>GermanyVisaOrg Website</option>
                                            <option>VisaGuideWorld Website</option>
                                            <option>SchengenVisaInfo Website</option>
                                            <option>Google Ads</option>
                                            <option>Visa Reservation Blog Article</option>
                                            <option>SchengenVisaCommunity Facebook Group</option>
                                            <option>Youtube</option>
                                            <option>Discussion Board</option>
                                            <option>Social Media-Facebook, Twitter</option>
                                            <option >Quora</option>
                                            <option >Friends & Family</option>
                                            <option>Google Search Engine</option>
                                            <option>I'm not sure</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Col md={6}>
                                        <Form.Label>Are You One Of The Travelers Making The Payment? *</Form.Label>
                                        <Form>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`} className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name="group1"
                                                        type={type}
                                                        id={`inline-${type}-1`}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="No"
                                                        name="group1"
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                    />
                                                </div>
                                            ))}
                                        </Form>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="validationCustom02">
                                            <Form.Label>Provide The Name On The Billing Account?*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter your name as you are making the payment"
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Wrapper>
    )
}

export default GeneralInformation
const Wrapper = styled.section`
font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
    .travel-input {
	padding: 50px;
	background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    .interview-tp {
    margin-top: -21px;
}
label{
    margin-bottom:5px;
}
    }
   

    @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
        padding:0;
            }
            @media (max-width: ${({ theme }) => theme.breakpoints.medium}) { 
                .travel-input {
               padding: 15px;
               .interview-tp {
                margin-top: 0px;
            }
               
           }
         
        }
`;