import React from 'react'
import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';



const General = () => {
    return (
        <Wrapper>
            <Container>

                <div className='travel-input'>
                    <h2 className="text-center mb-4">General</h2>
                    <Accordion defaultKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>  I didn’t receive the requested documents on time. What should I do? </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>If you didn’t receive the requested documents within the time frame you had requested, then here are our suggestions:</p>

                                    <p>1) Check the inbox of the email id provided as part of the submission<br />2) Check the spam folder<br />3) Check the inbox for any follow up instructions</p>

                                    <p>If you still have issues then please reach out to us immediately either by online chat, phone or send email to admin@visareservation.com. We will advise as appropriate.</p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>  Why should I buy Travel Guides and how are they useful for the visa?  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>Most common reason for visa rejection is “Justification for the purpose and the condition of the intended stay was not provided”. Use these travel guides to create a powerful cover letter for visa and how you plan to spend your time during the trip.</p>
                                    <p>The travel guides we provide have detailed information about various places, transportation details, restaurants, historical places etc. When you write a cover letter to the embassy about your purpose of the visit then you can use some of the information in the travel guides to show how you plan to spend your time while you are in the city so that your case looks more genuine.</p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>  Why should I buy Visa Packages and how are they useful for the visa? </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>The visa application packages give you tons of valuable information and access to the resources, tips and tricks that you need to know to apply for visa hassle free. Also, follow the visa package from start to finish to make the most of your visa application process, and save time.</p>

                                    <p>Visa application process sometimes can be overwhelming and there are many things to keep track of. You can leave all the stress behind by following the important information, resources, tips and tricks outlined in these visa packages.</p><p>For ex: Schengen Visa Package Includes below:</p>

                                    <p>1) Schengen Visa Application Guide<br />2) Video and Audio – How to Fill the Application Form<br />3) Schengen Visa Types<br />4) Uniform Schengen Visa<br />5) Who Needs a Schengen Visa?<br />6) Schengen Visa Statistics 2018<br />7) How to Apply for Schengen Visa<br />8) Reasons for Schengen Visa Rejection<br />9) Sample Letters<br />10) Cover Letter Guidance and Template<br />11) Invitation Letter Guidance and Template<br />12) Sponsorship Letter Guidance and Template<br />13) NOC Letter Guidance and Template<br />14) Refusal Letter Appeal Guidance and Template<br />15) Travel Itinerary Guidance and Template<br />16) Checklist of Documents for All Applicants<br />17) Directories of Embassies/Consulates/Visa Processing Agencies<br />18) Top 10 Easy Schengen Countries to Apply<br />19) Easiest Schengen Visa Countries to Receive Visa From<br />20) Schengen Visa Application Form<br />21) Step By Step Instructions to Fill the Visa Application Form<br />22) Schengen Countries<br />23) Resources, Tips and Much More…!!!</p> </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>  Will there be any additional charge on my credit or debit card other than the amount I paid while submitting the order?  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>No. The amount you paid as part of submitting the order is the only charge that you will see on your card statements.</p>

                                    <p>We take care of reservations cancellations for free as opposed to the airlines charging an additional cancellation fee when you book on your own and cancel a flight reservation – that’s what we mean by “Free Cancellations”!</p>

                                    <p>You are also not obligated to purchase these reservations from us and you can make your own travel plans once the visa is approved – that’s what we mean by “No Obligation”!</p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>  I received the itinerary from you! But my travel plans have now changed. What should i do? </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>No issues at all. W<span>e can help you with the changes and modifications as long as it is for the same trip. All the revisions will be emailed within <strong>24 hours</strong> at no cost to you.</span>
                                        <span>However, If you would like to prioritize the revision request and wish to get the updated itinerary </span>
                                        <b>within 6 hrs, </b>kindly pay <span>using the below link</span></p>

                                    <p>
                                        <Link to="#"><strong>Revision Request – Urgent</strong></Link></p>

                                    <p>
                                        <span>If you are not in rush, then you can submit your revision request using the below link to get the revised document within 24 hours at no cost.</span></p>

                                    <p>
                                        <Link to="#"><strong>Revision Request</strong></Link>
                                    </p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>  The dates and cities i have provided while placing the order were incorrect. What should i do to have those corrected?  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>Not to worry! W<span>e can help you with the changes and modifications. All the revisions will be emailed within <strong>24 hours</strong> at no cost to you.</span>
                                        <span>However, If you would like to prioritize the revision request and wish to get the updated itinerary </span>
                                        <b>within 6 hrs, </b>kindly pay <span>using the below link</span>
                                    </p>
                                    <p>
                                        <Link to="#">
                                            <strong>Revision Request – Urgent</strong>
                                        </Link>
                                    </p>
                                    <p>
                                        <span>If you are not in rush, then you can submit your revision request using the below link to get the revised document within 24 hours at no cost.</span>
                                    </p>
                                    <p>
                                        <Link to="#"><strong>Revision Request</strong>
                                        </Link>
                                    </p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>  The dates and cities i have provided while placing the order were incorrect. What should i do to have those corrected?  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>Not to worry! W<span>e can help you with the changes and modifications. All the revisions will be emailed within <strong>24 hours</strong> at no cost to you.</span>
                                        <span>However, If you would like to prioritize the revision request and wish to get the updated itinerary </span>
                                        <b>within 6 hrs, </b>kindly pay <span>using the below link</span>
                                    </p>
                                    <p>
                                        <Link to="#">
                                            <strong>Revision Request – Urgent</strong>
                                        </Link>
                                    </p>
                                    <p>
                                        <span>If you are not in rush, then you can submit your revision request using the below link to get the revised document within 24 hours at no cost.</span>
                                    </p>
                                    <p>
                                        <Link to="#"><strong>Revision Request</strong>
                                        </Link>
                                    </p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>  The dates and cities i have provided while placing the order were incorrect. What should i do to have those corrected?  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>Not to worry! W<span>e can help you with the changes and modifications. All the revisions will be emailed within <strong>24 hours</strong> at no cost to you.</span>
                                        <span>However, If you would like to prioritize the revision request and wish to get the updated itinerary </span>
                                        <b>within 6 hrs, </b>kindly pay <span>using the below link</span>
                                    </p>
                                    <p>
                                        <Link to="#">
                                            <strong>Revision Request – Urgent</strong>
                                        </Link>
                                    </p>
                                    <p>
                                        <span>If you are not in rush, then you can submit your revision request using the below link to get the revised document within 24 hours at no cost.</span>
                                    </p>
                                    <p>
                                        <Link to="#"><strong>Revision Request</strong>
                                        </Link>
                                    </p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>   What is your refund policy?  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <h3>Refunds: (if applicable)</h3>
                                    <p>Once you place an itinerary order with us and make the payment, you will receive an email confirmation from Visa Reservation Services to notify you that we have received your order and payment.</p>
                                    <p>If you need amendments to your itinerary in terms of date changes or travel changes, we do unlimited reasonable corrections for you.</p>
                                    <h3>Refund policy for Flights and Hotels:</h3>
                                    <p><span>What you pay is the service fee to make reservations for you. Please note that the service fee doesn’t apply towards the purchase of the actual tickets.</span></p>
                                    <p><span>We provide refunds for flights and hotels when your visa is denied due to our itineraries and reasons within the scope of our services only and not for any other reasons. You should provide us with the complete letter of rejection stamped and signed by the embassy authority as a single scanned attachment with the name of the beneficiary reflecting on it, and other rejection documents to process your refund request. Please refer to <strong><span>“Submit Refund Request”</span> </strong>section below to get started.</span></p>
                                    <h3>Refund policy for Insurance:</h3>
                                    <p><span>Insurance can be canceled prior to the start date and the insurance amount will be refunded after deducting the service, insurance cancellation and processing fee of 50 US dollars. Please refer to <strong><span>“Submit Refund Request”</span> </strong>section below to get started.</span></p>
                                    <h3>Refund policy for Travel Guides:</h3><p>The fee for travel guides is non-refundable under any circumstances.</p>
                                    <h3>Refund policy for Visa Interview Questions Guide:</h3><p>The fee for the visa interview questions guide is non-refundable under any circumstances.</p><h3>Refund policy for Visa Appointment(s):</h3>
                                    <p>What you pay is the service fee to book visa appointments for you. The service fee is fully refundable only for the below conditions. The appointment service fee is non-refundable in all other circumstances not listed below:</p>
                                    <p>1) If we can’t book an appointment for you<br />2) OR, If we receive a service cancellation request from you before the visa appointment has been made</p>
                                    <p><span>Please refer to <strong><span>“Submit Refund Request”</span> </strong>section below to get started.</span></p><h3>Submit Refund Request:</h3><p>Submit <Link to="#"><span><strong>REFUND REQUEST</strong></span></Link> using this Link<span><strong>within 60 days of the service delivery</strong></span>.</p>
                                    <p>If your refund request is approved, it will be processed immediately and you will be notified, and the credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.</p>
                                    <h3>Late or missing refunds (if applicable)</h3>
                                    <p>If you have not received a refund yet, first double-check your bank account to be sure that the money has not been added to your account.</p>
                                    <p>Then, contact your credit card company, it may take some time before your refund is officially posted.</p>
                                    <p>Next, contact your bank. There is often some processing time before a refund is posted.</p>
                                    <p>If you’ve done all of this and you still have not received your refund yet, please contact us at service@visareservation.com</p>
                                    <p>Please refer to the up to date <Link to="#"> refund policy </Link> and <Link to="#"> terms and conditions </Link> before using our services.</p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9">
                            <Accordion.Header>   Can i print and submit the itinerary that i have received from you to the embassy?  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>Yes. We will email you the itinerary in the PDF format and you can just print it out and take it to the embassy to show it as a proof of travel arrangement.</p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Container>


        </Wrapper>
    )
}

export default General
const Wrapper = styled.section`
font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
.travel-input {
    padding: 50px;
    background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    .accordion-button {
        font-size: 16px;
        font-weight: 500;
       
    }
}
@media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding:0px;
}
@media (max-width: ${({ theme }) => theme.breakpoints.small}) { 
    .travel-input {
   padding: 15px;
   
}
}
`;