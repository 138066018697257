import { useState } from 'react';
import React from 'react';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import axios from "axios";

    const travel_details = [];

    function handleChange(i, event) {
     travel_details[event.target.name] = event.target.value;
    }
const TravelersDetails = () => {
    const [validated, setValidated] = useState(false);        

    const handleSubmit = (event) => {
                
        const form = event.currentTarget;        
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }else{
           // console.log(this.inputNode.value)

            //alert('let me hit the API');
            event.preventDefault();
           console.log(event.target[5].value);
            
            const totaltraveler = event.target.totaltraveler.value;
            let mutipletravelData = "";
            if(totaltraveler == 1){
                mutipletravelData = {
                    traveler1: event.target.firstname_1.value + "," + event.target.lastname_1.value,
                }
            }else if(totaltraveler == 2){
                mutipletravelData = {
                    traveler1: event.target.firstname_1.value + "," + event.target.lastname_1.value,
                    traveler2: event.target.firstname_2.value + "," + event.target.lastname_2.value,
                }
            }else if(totaltraveler == 3){
                mutipletravelData = {
                    traveler1: event.target.firstname_1.value + "," + event.target.lastname_1.value,
                    traveler2: event.target.firstname_2.value + "," + event.target.lastname_2.value,
                    traveler3: event.target.firstname_3.value + "," + event.target.lastname_3.value,                    
                }
            }else if(totaltraveler == 4){
                mutipletravelData = {
                    traveler1: event.target.firstname_1.value + "," + event.target.lastname_1.value,
                    traveler2: event.target.firstname_2.value + "," + event.target.lastname_2.value,
                    traveler3: event.target.firstname_3.value + "," + event.target.lastname_3.value,
                    traveler4: event.target.firstname_4.value + "," + event.target.lastname_4.value,
                }
            }else if(totaltraveler == 5){
                mutipletravelData = {
                    traveler1: event.target.firstname_1.value + "," + event.target.lastname_1.value,
                    traveler2: event.target.firstname_2.value + "," + event.target.lastname_2.value,
                    traveler3: event.target.firstname_3.value + "," + event.target.lastname_3.value,
                    traveler4: event.target.firstname_4.value + "," + event.target.lastname_4.value,
                    traveler5: event.target.firstname_5.value + "," + event.target.lastname_5.value,
                }
            }else if(totaltraveler == 6){
                mutipletravelData = {
                    traveler1: event.target.firstname_1.value + "," + event.target.lastname_1.value,
                    traveler2: event.target.firstname_2.value + "," + event.target.lastname_2.value,
                    traveler3: event.target.firstname_3.value + "," + event.target.lastname_3.value,
                    traveler4: event.target.firstname_4.value + "," + event.target.lastname_4.value,
                    traveler5: event.target.firstname_5.value + "," + event.target.lastname_5.value,
                    traveler6: event.target.firstname_6.value + "," + event.target.lastname_6.value,
                }
            }else if(totaltraveler == 7){
                mutipletravelData = {
                    traveler1: event.target.firstname_1.value + "," + event.target.lastname_1.value,
                    traveler2: event.target.firstname_2.value + "," + event.target.lastname_2.value,
                    traveler3: event.target.firstname_3.value + "," + event.target.lastname_3.value,
                    traveler4: event.target.firstname_4.value + "," + event.target.lastname_4.value,
                    traveler5: event.target.firstname_5.value + "," + event.target.lastname_5.value,
                    traveler6: event.target.firstname_6.value + "," + event.target.lastname_6.value,
                    traveler7: event.target.firstname_7.value + "," + event.target.lastname_7.value,
                }
            }

            console.log(mutipletravelData);
            const email = event.target.email.value;
            const phone = event.target.phone.value;            
            const flightdetails = event.target.flightdetails.value;

            const travelarData = {
                totaltraveler: totaltraveler,
                delivery_email: email,
                phone: phone,
                mutipletravelData: mutipletravelData,
                flightdetails: flightdetails,
            };

            console.log(travelarData);
              axios
              .post("https://rogerapi.app2dev.in/api/travelersdetails", travelarData, {
                  headers: {
                    'content-type': 'multipart/form-data',
                  withCredentials: true,
                  },
              })
              .then((response) => {
                  if (response.data.status == "200") {  
                   const traveler_id =     response.data.traveler_id;  
                    alert('Details Submitted');
                    console.log('success');
                    console.log(traveler_id);
                    document.getElementById("traveler_id").value = traveler_id;
                  }
                  if (response.data.status == "401") {                      ;
                     const dataError = response.data.message;
                     alert(dataError);
                   }
              })
              .catch((error) => {
                  //console.error(error); // Handle any errors
                })
        }

        setValidated(true);
    };

    const [selectedOption, setSelectedOption] = useState([1]);
    const a=[];
    const handleSelectChange = (event) => {
        const data=event.target.value;
   
        const numTravelers = parseInt(data);
         {selectedOption[0] === 1 && (
          <>
            {selectedOption.map((_, index) => (
              index > 0 && (
                <React.Fragment key={index}>
                  <Form.Group as={Col} md="6" className='mb-4' controlId={`validationCustom01-${index}`}>
                    <Form.Label>{`Traveler ${index + 1} First Name (Should match passport)*`}</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="First name"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6" className='mb-4' controlId={`validationCustom02-${index}`}>
                    <Form.Label>{`Traveler ${index + 1} Last Name (Should match passport)*`}</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Last name"
                    />
                  </Form.Group>
                </React.Fragment>
              )
            ))}
          </>
        )} 
        //console.log(numTravelers)
    
         
        for (let i = 0; i < numTravelers; i++) {
            a.push(i);
        }
        setSelectedOption(a)
       // console.log(a)
      };

      //console.log("Rendered selectedOption:", selectedOption);

    return (
        <Wrapper className='travel-info'>
            <Container>
                <div className='travel-input'>
                    <Row>
                        <Col sm={12}>
                            <h2 className="text-center mb-4">Travelers Details</h2>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">
                               
                                <Form.Group as={Col} md="4" className='mb-4' controlId="validationTraveler">
                                        <Form.Label>No. Of Travelers*</Form.Label>
                                        <Form.Select aria-label="Default select example" name="totaltraveler" onChange={handleSelectChange} value={selectedOption.length}>
                                            <option value="1" selected="selected">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7 and more</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustomUsername">
                                        <Form.Label>Username</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                placeholder="John.rick@gmail.com"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter a vaild email.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationPhone">
                                        <Form.Label>Phone No.*</Form.Label>
                                        <Form.Control
                                            required
                                            type="tel"
                                            name="phone"
                                            placeholder="Country Code + Phone Number"                                               
                                            pattern="[+][0-9]{10,15}"
                                        />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter vaild phone number.
                                            </Form.Control.Feedback>
                                    </Form.Group>

                                    
                                    {selectedOption.map((option, index) => (
                                        <React.Fragment key={index}>
                                            <Form.Group as={Col} md="6" className='mb-4' controlId={`validationCustom01-${index}`}>
                                            <Form.Label>{`Traveler ${index + 1} First Name (Should match passport)*`}</Form.Label>
                                            <Form.Control
                                                required
                                                name={"firstname_" + (index+1)}
                                                type="text"
                                                placeholder="First name"
                                                onChange={(e) => handleChange(index, e)} 
                                            /> 
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" className='mb-4' controlId={`validationCustom02-${index}`}>
                                            <Form.Label>{`Traveler ${index + 1} Last Name (Should match passport)*`}</Form.Label>
                                            <Form.Control
                                                required
                                                name={"lastname_" + (index+1)}
                                                type="text"
                                                placeholder="Last name" 
                                                onChange={(e) => handleChange(index, e)}                                              
                                            />
                                            </Form.Group>
                                        </React.Fragment>
                                        ))}
                                    
                                    
                                    
                                    <Form.Group as={Col} md="12" controlId="validationCustomUsername">

                                        <div className="gfield_description">Provide Travelers Flight Details:*</div>
                                        <Form.Label>Departure date - Departing city/airport - Arrival city/airport</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            required
                                            name="flightdetails"
                                            placeholder="Departing on June 1, 2017 from Los Angeles(LAX) to Frankfurt(FRA); Returning on June 10, 2017 from Frankfurt(FRA) to Los Angeles(LAX) or Type &quot;Please see attachment&quot;"
                                            style={{ height: '200px' }}
                                        />
                                    </Form.Group>
                                    <Col sm={12}>
                                        <div className="flight-prize">
                                            <div className="flight-p">Your Flight Price Is:</div>
                                            <div className="total-value">$40</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Button type="submit" className='custom-btn btn-3'><span>Submit</span></Button>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Wrapper>
    )
}

export default TravelersDetails
const Wrapper = styled.section`
    font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
 
    .travel-input {
	padding: 50px;
	background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
        .flight-prize {
        display: flex;
        justify-content: space-between;
        font-size: 25px;
        font-weight: 500;
        position: relative;
        }

        .flight-p {
        padding: 7px;
        &: before {content: "";
        position: absolute;
        width: 72%;
        height: 3px;
        top: 30px;
        text-align: center;
        left: 275px;
        border: 2px dotted #069460;
    }
    }
    .total-value {
	text-transform: uppercase;
	font-size: 35px;
	font-weight: 700;
	font-family: "Poppins";
	color: #069460;
}
label{
    margin-bottom:5px;
}
}



@media (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
.flight-p {
    padding: 7px;
    &: before
     {
        display:none;
   
}
}
@media (max-width: ${({ theme }) => theme.breakpoints.medium}) { 
    .travel-input {
   padding: 15px;
   
}
}
@media (max-width: ${({ theme }) => theme.breakpoints.small}) { 
    .travel-input {
    .flight-prize {
        font-size:19px;
        display:block;
        .total-value {
            font-size: 35px;
            color: #069460;
            text-align: center;
            background: #bbf3e0;
        }
        
    }
    
}
`;