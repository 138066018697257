import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import iconone from '../../assets/images/icon-1.svg'
import icontwo from '../../assets/images/icon-2.svg'
import iconthree from '../../assets/images/icon-3.svg'
import iconfour from '../../assets/images/icon-4.svg'

const WhatWeOffer = () => {
    return (
        <Wrapper className='what-we-offer'>
            <Container>
                <Row>
                    <Col sm={12}>
                        <h2>What we offer</h2>
                    </Col>
                    <Col sm="3">
                        <div className='icon-row'>
                            <img src={iconone} />
                        </div>
                        <h3>Flight itinerary</h3>
                        <p>We provide verifiable one way, round trip, and multi-city flight bookings for any country visa within minutes at an affordable price without </p>
                    </Col>


                    <Col sm="3">
                        <div className='icon-row'>
                            <img src={icontwo} />
                        </div>
                        <h3>Hotel booking</h3>
                        <p>We provide verifiable one way, round trip, and multi-city flight bookings for any country visa within minutes at an affordable price without </p>
                    </Col>


                    <Col sm="3">
                        <div className='icon-row'>
                            <img src={iconthree} />
                        </div>
                        <h3>Travel insurance</h3>
                        <p>We provide verifiable one way, round trip, and multi-city flight bookings for any country visa within minutes at an affordable price without </p>
                    </Col>


                    <Col sm="3">
                        <div className='icon-row'>
                            <img src={iconfour} />
                        </div>
                        <h3>Buyer protection</h3>
                        <p>We provide verifiable one way, round trip, and multi-city flight bookings for any country visa within minutes at an affordable price without </p>
                    </Col>

                </Row>
            </Container>
        </Wrapper>
    )
}

export default WhatWeOffer



const Wrapper = styled.section`
   text-align:center;
   padding:60px 0;
    h2{
        padding-bottom:40px;
    }
    p{padding:0 30px}
        .icon-row {
            width: 56px;
            height: 56px;
            background: #CEF2E9;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            margin: 0 auto 20px;
        }

        @media (max-width: ${({ theme }) => theme.breakpoints.large}){
            padding: 30px 0;
            .col-sm-3 {
                flex: 0 0 auto;
                width: 50%;
            }
        }
        @media (max-width: ${({ theme }) => theme.breakpoints.small}){
            .col-sm-3 {
                flex: 0 0 auto;
                width: 100%;
            } 
        }
        
`;