import React from 'react'
import FaqBanner from '../components/Faq/FaqBanner'
import Reservations from '../components/Faq/Reservations'
import FaqServices from '../components/Faq/FaqServices'
import Specific from '../components/Faq/Specific'
import General from '../components/Faq/General'
import Appointment from '../components/Faq/Appointment'
import UsefulTips from '../components/Faq/UsefulTips'
import SchengenVisa1 from '../components/Faq/SchengenVisa1'
import SchengenVisa2 from '../components/Faq/SchengenVisa2'
import ContactNow from '../components/Faq/ContactNow'




const Faq = () => {
  return (
    <>
    <FaqBanner/>
    <Reservations/>
    <FaqServices/>
    <Specific/>
    <General/>
    <Appointment/>
    <UsefulTips/>
    <SchengenVisa1/>
    <SchengenVisa2/>
    <ContactNow/>

    </>
  )
}

export default Faq