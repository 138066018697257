import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import videoImage from "../../assets/images/video11.png";
import image1 from "../../assets/images/image1.png";
import image2 from "../../assets/images/image2.png";
import image312 from "../../assets/images/image312.png";
import image412 from "../../assets/images/image412.png";
import image5 from "../../assets/images/image5.png";
import image6 from "../../assets/images/image6.png";
import image7 from "../../assets/images/image7.png";
import greeceflag from "../../assets/images/greeceflag.png";
import image8 from "../../assets/images/image8.png";
import image9 from "../../assets/images/image9.png";
import image10 from "../../assets/images/image10.png";
import image11 from "../../assets/images/image11.png";
import image12 from "../../assets/images/image12.png";
import image13 from "../../assets/images/image13.png";
import image14 from "../../assets/images/image14.png";
import image15 from "../../assets/images/image15.png";
import { Link } from "react-router-dom";

const CheckVisaCountry = () => {
  return (
    <Wrapper className="check-visa">
      <Container>
        <Row>
          <h2 className="text-center">Check visa requirements</h2>
        </Row>
        <Row>
          <Col sm={5}>
            <img className="video-im" src={videoImage} />
          </Col>
          <Col sm={7}>
            <div class="blck why__blck why__blck-2">
              <div class="tbl-images">
                <Row>
                  <Col sm={4}>
                    <div class="tbl">
                      <div class="tbl-cell cell-1 mdl">
                        <img src={image1} />
                      </div>
                      <div class="tbl-cell cell-2 mdl">
                        <a href="#" target="_self">
                          Austria
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="tbl">
                      <div class="tbl-cell cell-1 mdl">
                        <img src={image2} />
                      </div>
                      <div class="tbl-cell cell-2 mdl">
                        <a href="#" target="_self">
                          Besmium
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="tbl">
                      <div class="tbl-cell cell-1 mdl">
                        <img src={image312} />
                      </div>
                      <div class="tbl-cell cell-2 mdl">
                        <a href="#" target="_self">
                          Czech Republic
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="tbl">
                      <div class="tbl-cell cell-1 mdl">
                        <img src={image412} />
                      </div>
                      <div class="tbl-cell cell-2 mdl">
                        <a href="#" target="_self">
                          China
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="tbl">
                      <div class="tbl-cell cell-1 mdl">
                        <img src={image5} />
                      </div>
                      <div class="tbl-cell cell-2 mdl">
                        <a href="#" target="_self">
                          Denmark
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="tbl">
                      <div class="tbl-cell cell-1 mdl">
                        <img src={image6} />
                      </div>
                      <div class="tbl-cell cell-2 mdl">
                        <a href="#" target="_self">
                          Estonia
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="tbl">
                      <div class="tbl-cell cell-1 mdl">
                        <img src={image7} />
                      </div>
                      <div class="tbl-cell cell-2 mdl">
                        <a href="#" target="_self">
                          Germany
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="tbl">
                      <div class="tbl-cell cell-1 mdl">
                        <img src={greeceflag} />
                      </div>
                      <div class="tbl-cell cell-2 mdl">
                        <a href="#" target="_self">
                          Greece
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="tbl">
                      <div class="tbl-cell cell-1 mdl">
                        <img src={image8} />
                      </div>
                      <div class="tbl-cell cell-2 mdl">
                        <a href="#" target="_self">
                          Hungary
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="tbl">
                      <div class="tbl-cell cell-1 mdl">
                        <img src={image9} />
                      </div>
                      <div class="tbl-cell cell-2 mdl">
                        <a href="#" target="_self">
                          Iceland
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="tbl">
                      <div class="tbl-cell cell-1 mdl">
                        <img src={image10} />
                      </div>
                      <div class="tbl-cell cell-2 mdl">
                        <a href="#" target="_self">
                          Italy
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="tbl">
                      <div class="tbl-cell cell-1 mdl">
                        <img src={image11} />
                      </div>
                      <div class="tbl-cell cell-2 mdl">
                        <a href="#" target="_self">
                          Latvia
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="tbl">
                      <div class="tbl-cell cell-1 mdl">
                        <img src={image12} />
                      </div>
                      <div class="tbl-cell cell-2 mdl">
                        <a href="#" target="_self">
                          Netherlands
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="tbl">
                      <div class="tbl-cell cell-1 mdl">
                        <img src={image13} />
                      </div>
                      <div class="tbl-cell cell-2 mdl">
                        <a href="#" target="_self">
                          Portugal
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="tbl">
                      <div class="tbl-cell cell-1 mdl">
                        <img src={image14} />
                      </div>
                      <div class="tbl-cell cell-2 mdl">
                        <a href="#" target="_self">
                          Spain
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="tbl">
                      <div class="tbl-cell cell-1 mdl">
                        <img src={image15} />
                      </div>
                      <div class="tbl-cell cell-2 mdl">
                        <Link to="#" target="_self">
                          Visa Countries
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <div class="why__view-all">
                      <a href="#" target="_self">
                        View All
                      </a>
                      <span>»</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default CheckVisaCountry;

const Wrapper = styled.section`
  background: #f7f7f7;
  padding: 60px 0px;
  a {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    padding-left: 3px;
    text-decoration: none;
  }
  h2 {
    text-align: center;
    padding-bottom: 20px;
  }
  .tbl-cell {
    display: table-cell;
    padding-left: 1px;
    padding-right: 2px;
  }
  .why__blck-2 img {
    width: 42px;
    height: 24px;
  }
  .tbl {
    padding: 8px 0px;
  }
  .why__view-all {
    padding: 20px 10px;
    a {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #079561;
    }
    span {
      color: #079561;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    .col-sm-4 {
      flex: 0 0 auto;
      width: 50%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 25px 0px 0px 0px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    .col-sm-5 {
      flex: 0 0 auto;
      width: 100%;
    }
    .col-sm-4 {
      flex: 0 0 auto;
      width: 50%;
    }
  }
`;
