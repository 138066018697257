import React from 'react'
import FillDetails from "../components/TravelDetails4/FillDetails";
import FlightReservation from "../components/TravelDetails4/FlightReservation"
import TravelersDetails from '../components/TravelDetails4/TravelersDetails'
import GeneralInformation from '../components/TravelDetails4/GeneralInformation'
import HotelDetail from '../components/TravelDetails4/HotelDetail'
import InsuranceInformation from '../components/TravelDetails4/InsuranceInformation'
import TravelGuides from "../components/TravelDetails4/TravelGuides"
import SchengenVisa from "../components/TravelDetails4/SchengenVisa"
import VisaApplication from "../components/TravelDetails4/VisaApplication"
import OrderSummary from "../components/TravelDetails4/OrderSummary"
import PaymentMethod from "../components/TravelDetails4/PaymentMethod";



const TravelDetails3 = () => {
    return (
        <>
            <FillDetails />
            <FlightReservation />
            <TravelersDetails />
            <GeneralInformation />
            <HotelDetail />
            <InsuranceInformation />
            <TravelGuides />
            <SchengenVisa />
            <VisaApplication />
            <OrderSummary />
            <PaymentMethod />


        </>
    )
}

export default TravelDetails3