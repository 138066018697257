import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components';
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";

const TopBar = () => {
    return (
        <Wrapper className='topbar'>
            <Container>
                <Row>
                    <Col className='social-ic' sm={6}>
                        <ul className='d-flex'>
                            <li><a href="#"><FaFacebookF /></a></li>
                            <li><a href="#"><FaTwitter /></a></li>
                            <li><a href="#"><FaYoutube /></a></li>
                        </ul>
                    </Col>
                    <Col sm={6} className='d-flex justify-content-end'>
                        <div className='phone'><span><FaPhone /></span>+1 (919)-263-2551</div>
                        <div className='langues'>English</div>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    )
}

export default TopBar


const Wrapper = styled.section`
    width: 100%;
    padding: 9px 0;
    margin: 0px;
    border-bottom: 1px solid #ECECEC;
   ul{
    list-style:none;
    padding:0px;
    margin:0px;
     li{
        position:relative;
        margin:0px 5px;
        a{
            background:#CEF2E9;
            color:#4B4B4B;
            border-radius: 50px;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width:25px;
           height:25px;
        }
     }
   }
.phone {
    padding: 0 47px;
    color: #1C1C1C;
    font-size: 13px;
    font-weight: 500;
    span{
        color:#069460;
    }
}
.langues{
    color: #1C1C1C;
    font-size: 13px;
    font-weight: 500;
}

@media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    .col-sm-6 {
        flex: 0 0 auto;
        width: 100%;
    }
.social-ic {
    display: none;
}
}
`;