import React from 'react'
import FillDetails from "../components/TravelDetails8/FillDetails"
import FlightReservation from '../components/TravelDetails8/FlightReservation'
import TravelersDetails from '../components/TravelDetails8/TravelersDetails'
import GeneralInformation from '../components/TravelDetails8/GeneralInformation'
import HotelDetail from '../components/TravelDetails8/HotelDetail'
import InsuranceInformation from '../components/TravelDetails8/InsuranceInformation'
import TravelGuides from "../components/TravelDetails8/TravelGuides"
import SchengenVisa from "../components/TravelDetails8/SchengenVisa"
import VisaApplication from "../components/TravelDetails8/VisaApplication"
import OrderSummary from "../components/TravelDetails8/OrderSummary"
import PaymentMethod from "../components/TravelDetails8/PaymentMethod"




const TravelDetails8 = () => {
    return (
        <>
        <FillDetails />
           <FlightReservation />
            <TravelersDetails />
            <GeneralInformation />
            <HotelDetail />
            <InsuranceInformation />
            <TravelGuides />
            <SchengenVisa />
            <VisaApplication />
            <OrderSummary />
            <PaymentMethod />
           


        </>
    )
}

export default TravelDetails8