import React from 'react'
import { Container, Row, Form, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaCheck } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";




const Footer = () => {
  return (
    <>
      <Wrapper className='footer'>
        <div class="footer-top">
          <Container>
            <Row className="footer-main">
              <Col sm={3}>
                <div class="widget course-links-widget">
                  <h5 class="widget-title">24 HOURS DELIVERY</h5>
                  <ul class="courses-link-list"><li><Link to="#"><span><FaCheck /></span>Flight Reservation </Link>
                  </li>
                    <li><Link to="#"><span><FaCheck /></span>Hotel Booking </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Flight + Hotel </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Flight + Hotel + Insurance </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Flight + Insurance </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Insurance </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Hotel + Insurance </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Book Appointment </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Schengen Visa Interview Guide </Link>
                    </li>
                  </ul>
                </div>
              </Col>


              <Col sm={3}>
                <div class="widget course-links-widget">
                  <h5 class="widget-title">6 HOURS DELIVERY</h5>
                  <ul class="courses-link-list">
                    <li>
                      <Link to="#"><span><FaCheck /></span>Flight Reservation </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Hotel Booking </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Flight + Hotel </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Flight + Hotel + Insurance </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Flight + Insurance </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Insurance </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Hotel + Insurance </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Book Appointment </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Schengen Visa Interview Guide</Link>
                    </li>
                  </ul>
                </div>
              </Col>

              <Col sm={3}>
                <div class="widget latest-news-widget">
                  <h5 class="widget-title">Links</h5>
                  <ul class="courses-link-list">
                    <li>
                      <Link to="#"><span><FaCheck /></span>Customer Reviews </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Worldwide Visa </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Travel Guides </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Visa Packages</Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Blog</Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Terms &amp; Conditions </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Refund Policy </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Book Appointment </Link>
                    </li>
                    <li>
                      <Link to="#"><span><FaCheck /></span>Contact us </Link>
                    </li>
                  </ul>
                </div>
              </Col>

              <Col sm={3}>
                <div class="widget newsletter-widget">
                  <h5 class="widget-title">Newsletter</h5>
                  <div class="footer-newsletter">
                    <p class="dummy">Stay informed about our latest product launches, updates, and enhancements. </p>
                    <Form class="example">
                      <Form.Control type="text" placeholder="Newsletter" />
                      <Button>Go</Button>
                    </Form>
                    <br />
                    <p class="news-text">1135 Kildaire Farm Road, Second Floor, Suite #200, Cary, North Carolina - 27511 <br />United States of America </p>
                    <h5 class="socialtext">Social icons</h5>
                    <ul class="social-network social-circle">
                      <li>
                        <Link to="#" class="icoFacebook">
                          <FaFacebookF />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" class="icoTwitter">
                          <FaTwitter />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" class="icoLinkedin">
                          <FaLinkedinIn />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" class="icoYoutube">
                          <FaYoutube />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>


        <div class="footer-bottom">
          <Container>
            <Row>
              <Col sm={12}>
                <div class="copy-right-text">©2017-2023 VisaReservation.com, LLC(A USA Travel Company)</div>
              </Col>
            </Row>
          </Container>
        </div>
      </Wrapper>
    </>
  )
}

export default Footer



const Wrapper = styled.section`
    background: #079561;
    padding: 60px 0px 30px;
    color: #fff;
   
    p{
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    line-height: 25px;
    }
    .fa {
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    border-radius: 50%;
    text-align: center;
    width: 26px;
    height: 26px;
    font-size: 15px;
    line-height: 27px;
    background: #cef2e9;
    color:#333;
    
}
    .widget-title {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
}
ul{
        padding:0px;
        margin:0px;
        list-style:none;
        li {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
    a{
        color: #fff;
        text-decoration: none;
        span{
        padding-right:5px;        
        }
      }
     }
    }

    .dummy {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}
input{
    padding: 10px;
    font-size: 14px;
    border: none;
    float: left;
    width: 200px;
    background: #f1f1f1;
    height: 29px;
    outline: none;
    position: relative;
    border-radius:0px;
}
button {
    padding-left: 10px;
    height: 29px;
    font-size: 14px;
    border: none;
    text-align: center;
    background: #037048;
    cursor: inherit;
    position: absolute;
    border-radius:0px;
    &:hover{
        background:#016440;
    }
}
.news-text {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 20px;
}
.socialtext {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
}
.social-network {
    list-style: none;
    display: inline;
    padding: 0;
    li{
        display: inline;
    margin: 0 5px;
     a {
    display: inline-block;
    font-size: 12p
    
    x;
    position: relative;
    margin: 0 auto 0 auto;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    width: 26px;
    height: 26px;
    border: 1px solid #dcf3eb;
 }
}
}


.socialtext {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
}
.social-network li {
    display: inline;
    margin: 0 5px;
.social-circle li a {
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    width: 26px;
    height: 26px;
    font-size: 15px;
    border: 1px solid #7dbaa4;
}
.news-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 20px;
}
}

.footer-bottom {
    padding: 20px 0px 0px 0px;
    position: relative;
}
.copy-right-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 15px;    
    border-top: 1px solid #a7d5c4;
    padding: 19px 0 0;
}
.dummy {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color:#fff;
}

@media screen and (max-width: 1199px) {
  input{width:auto}
}

@media (max-width: ${({ theme }) => theme.breakpoints.large}){
  padding:20px 0px;
  .col-sm-3 {
    flex: 0 0 auto;
    width: 50%;
}
}
@media (max-width: ${({ theme }) => theme.breakpoints.small}){
  .col-sm-3 {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom:20px;
}
}
`;