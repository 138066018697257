import { useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap'
import styled from 'styled-components';

const HotelDetail = () => {
    return (
        <Wrapper>
            <Container>
                <div className='travel-input'>
                    <Row>
                        <h2 className="text-center mb-4">Hotel Details</h2>

                        <Form.Group as={Col} md="6" className='mb-4' controlId="validationCustomUsername">
                            <Form.Label>No. Of Hotels*</Form.Label>
                            <Form.Select aria-label="Default select example">

                                <option value="0" selected="selected">1 - 3</option>
                                <option value="10">4</option>
                                <option value="20">5</option>
                                <option value="30">6</option>
                                <option value="40">7</option>
                                <option value="50">8</option>
                                <option value="60">9</option>
                                <option value="70">10</option>
                                <option value="80">11</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustomUsername">

                            <div className="gfield_description">Provide Travelers Hotel Details:*</div>
                            <Form.Label>City - Checkin date - Checkout date</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="City - Frankfurt, Germany; Check in date - June 1, 2017; Check out date - Jun 10, 2017 or Type &quot;Please see attachment&quot;"
                                style={{ height: '200px' }}
                            />
                        </Form.Group>

                    </Row>
                </div>
            </Container>
        </Wrapper>
    )
}

export default HotelDetail
const Wrapper = styled.section`
font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
    .travel-input {
	padding: 50px;
	background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    .flight-prize {
    display: flex;
    justify-content: space-between;
    font-size: 25px;
    font-weight: 500;
    position: relative;
}
.flight-p:before {
    content: "";
    position: absolute;
    width: 72%;
    height: 3px;
    top: 30px;
    text-align: center;
    left: 275px;
    border: 2px dotted #069460;
}
.total-value {
    text-transform: uppercase;
    font-size: 35px;
    font-weight: 700;
    color: #069460;
}
.gfield_description {
    font-size: 14px;
    font-weight: 500;
}
.flight-p {
    padding: 7px;
}
label{
    margin-bottom:5px;
}
    
    }


    @media (max-width: ${({ theme }) => theme.breakpoints.medium}) { 
        .travel-input {
       padding: 15px;
       
   }
}
`;