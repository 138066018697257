import React from 'react'
import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from 'react-bootstrap/Accordion';
import { styled } from 'styled-components';
import { Link } from 'react-router-dom';


const FaqServices = () => {
    return (
        <Wrapper>
            <Container>

                <div className='travel-input'>
                    <h2 className="text-center mb-4">Services</h2>
                    <Accordion defaultKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>  Do you provide flight itinerary, hotel reservation and travel medical insurance for any country visa?  </Accordion.Header>
                            <Accordion.Body>
                                <p>You got it right! Yes, we provide valid flight and hotel itineraries for any country. Doesn’t matter where you are from and what country you are traveling to, we make our best efforts to serve you with reservations that work best for any visa application.</p>
                                <p>The insurance we provide meets International and Schengen requirements. We&nbsp;are not responsible for any damages, deaths or medical claims during travel. All insurance related questions, claims, concerns or issues should be directly dealt with the insurance provider or the agent provided in the visa letter.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header> Can i use your service to book any airline of my choice? </Accordion.Header>
                            <Accordion.Body>
                                <p>Absolutely! Feel free to let us know your preferred airlines while placing the order and we will try our best to honor your request.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header> I want to book flight and hotel reservations without purchasing the actual tickets. Are reservations accepted by the embassy?</Accordion.Header>
                            <Accordion.Body>
                                <p>That’s exactly why we are here for and why you should use our service! Yes, the reservations are perfectly&nbsp;<span>acceptable. </span>
                                </p>

                                <p>
                                    <span>The embassy actually recommends not purchasing actual tickets until the visa is approved.&nbsp;&nbsp;</span>We take away the overhead and ticket cancellation worries from you by making travel reservations on your behalf for a small service fee.</p>

                                <p>Moreover, see below that the <Link to="#">Spain consulate</Link>
                                    (Point# 9) doesn’t recommend you to purchase actual airline tickets until you get the visa.</p>

                                <p>“<em>Should I buy an airline ticket before the appointment? We recommend that you do not purchase travel tickets until your visa has been approved. On the other hand, you may provide us with a reservation, a planned itinerary or an online printout of a roundtrip ticket.</em>” – <strong>
                                    <em>Spain Embassy</em>
                                </strong>
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>  Is there a particular time period to use your service to book flight and hotel reservations?  </Accordion.Header>
                            <Accordion.Body>
                                <p>No, there is no specific time period to order reservations on our website. However, we recommend you to use our services three to four days prior to the visa interview.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header> Can you briefly provide what all services do you offer to your customers for visa application?  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>We provide flight reservations, hotel reservations, and travel insurance services for visa purposes&nbsp;along with free visa consultation, cover letter templates, and no objection letter templates.&nbsp;This service is for travelers applying for visas that require&nbsp;submitting confirmed flight and hotel reservations, and valid travel medical insurance. It is not for people who are planning to enter a country illegally or work illegally in a foreign country.</p>

                                    <p>Travelers need a visa to travel to several countries. So we provide fast, affordable and reliable services to customers by providing supporting documents and arranging flight reservations, hotel bookings, and travel insurance meeting visa application requirements.</p>

                                    <p>We also provide travel guides to customers upon request that will help them make the best use of their time and money during their stay in the city they are traveling to.

                                    </p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>  How reliable is your service and why should I use it?  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>1) Check what customers are saying about <Link to="#">our services</Link>
                                    </p>
                                    <p>
                                        <span>2) Our documents are authentic and perfect for any visa application purposes</span>
                                    </p>
                                    <p>
                                        <span>3) We provide 100% buyer protection through PayPal</span>
                                    </p>
                                    <p>
                                        <span>4) We take care of reservation cancellations at no additional cost to you</span>
                                    </p>
                                    <p>
                                        <span>5) We take away the risk from you and have you not spend thousands of dollars to hold the reservations</span>
                                    </p>
                                    <p>
                                        <span>6) We work with several airlines in our network</span>
                                    </p>
                                    <p>
                                        <span>7) We can accommodate multiple itinerary changes at no cost to you</span>
                                    </p>
                                    <p>
                                        <span>8) We have the ability to hold reservations for longer periods</span></p>
                                    <p>
                                        <span>9) We can rebook the same itinerary with different airlines instantly</span>
                                    </p>
                                    <p>
                                        <span>10) We are available 24/7 to address your concerns including when you are outside the embassy looking for immediate assistance!</span>
                                    </p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Container>


        </Wrapper>
    )
}

export default FaqServices
const Wrapper = styled.section`
font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
.travel-input {
    padding: 50px;
    background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    .accordion-button {
        font-size: 16px;
        font-weight: 500;
       
    }
}
@media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 0px;
}
@media (max-width: ${({ theme }) => theme.breakpoints.small}) { 
    .travel-input {
   padding: 15px;
   
}
}
`;