import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { styled } from 'styled-components'
import jumbo from "../../assets/images/jumbo.jpg";


const FillDetails = () => {
    return (
        <Wrapper className="jumbo">

            <div className="jumbo-2__txt">
                <h2>Fill details – Pay service fee  – Get confirmation in e-mail</h2>
                <p>In general, you can schedule visa appointment on your own with the embassy or visa application center for FREE. However, if you are running short of time or not sure which consulate to apply to or have no idea on how to get this done yourself then not to worry, we will make the appointment for you and manage the process end to end for a small service fee!</p>
            </div>

        </Wrapper>


    )
}

export default FillDetails
const Wrapper = styled.section`
    background:url(${jumbo});
    min-height:400px;
    background-repeat: no-repeat;
    position: relative;
    align-items: center;
    justify-content: center;
    display:flex;
    &:after{
        content: "";
    position: absolute;
    left: -1px;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    }
    h2{
      color:#fff;
    font-size: 35px;
    font-weight: 600;
  
    }
    .jumbo-2__txt {
        position: relative;
        z-index: 2;
        color: #fff;
        text-align: center;
        width: 95%;
        max-width:900px;
       
        p {
        font-size: 14px;
        color: #ffffff;
        font-weight: 400;
        line-height: 25px;
    }
   
   
}

@media (max-width: ${({ theme }) => theme.breakpoints.small}) {
     h2 {
     font-size: 25px;
   
    }
    
}
                       
    `;