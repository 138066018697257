import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { styled } from 'styled-components'
import Luxembourg from "../../assets/images/Luxembourg.jpg";
import { Link } from 'react-router-dom';


const FillDetails = () => {
    return (
        <Wrapper className="jumbo">

            <div className="jumbo-2__txt">
                <div class="blog__overl">
                </div>
                <div class="blog__blck">
                    <div class="blog__cont">
                        <div class="blog__date"> Aug 6, 2023 | 0 comments </div>
                        <div class="blog__jumbo-1-title"> Luxembourg Visa From India – How to Apply for Luxembourg Schengen Visa Application Requirements Guide </div>
                        <div class="blog__jumbo-1-txt">
                            <p> Luxembourg is a small country in the Schengen Area, but it’s one that has a lot to see and do. The small town center of Luxembourg itself is a lovely place to explore and there are plenty of bars... </p>
                            <p>
                                <Link class="blog__jumbo-1-read link-to-follow" to="#" target="_self">Read More &gt;</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </Wrapper>


    )
}

export default FillDetails
const Wrapper = styled.section`
    background:url(${Luxembourg});
    min-height: 650px;
    background-repeat: no-repeat;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    background-position: center;
    background-size: 100%;
    &:after{
        content: "";
    position: absolute;
    left: -1px;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    }
    h2{
      color:#fff;
    font-size: 35px;
    font-weight: 600;
    padding: 10px;
  
    }
    .jumbo-2__txt {
        position: relative;
        z-index: 2;
        color: #fff;
        text-align: center;
        width: 100%;
        p {
        font-size: 14px;
        color: #ffffff;
        font-weight: 400;
        line-height: 25px;
    }
    
   
   
}

                       
    `;