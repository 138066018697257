import React from "react";
import { Col, Container, Row, Tab, Tabs, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa6";

const Subscription = () => {
    return (
        <Wrapper className="subscription">
            <Container>
                <Tabs
                    defaultActiveKey="Delivery"
                    id="justify-tab-example"
                    className="mb-3"
                    justify
                >
                    <Tab eventKey="Delivery" title="24 Hr Email Delivery">
                        <Row>
                            <Col sm={3}>
                                <div className="subcri">
                                    <h3>FLIGHT ITINERARY</h3>
                                    <h4>ALL COUNTRY VISAS</h4>
                                    <div className="green-row">
                                        <h2>$40</h2>
                                        <span>Per Person</span>
                                    </div>
                                    <span className="hours">24 Hours Delivery</span>
                                    <ul>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Unlimited Flights
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Upto 4 Corrections
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Free Cancellations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                No Obligations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Free Cover & NOC Letter{" "}
                                            </Link>
                                        </li>
                                    </ul>
                                    <Link to="/traveldetails" className="btn">
                                        order now
                                    </Link>
                                </div>
                            </Col>

                            <Col sm={3}>
                                <div className="subcri">
                                    <h3>HOTEL BOOKING</h3>
                                    <h4>ALL COUNTRY VISAS</h4>
                                    <div className="green-row">
                                        <h2>$45</h2>
                                        <span>Per Person</span>
                                    </div>
                                    <span className="hours">24 Hours Delivery</span>
                                    <ul>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Upto 3 Hotels; Option to add more
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Upto 4 Corrections
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Stay Upto 3 Weeks/Hotel
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Free Cancellations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                No Obligations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Free Cover & NOC Letter
                                            </Link>
                                        </li>
                                    </ul>
                                    <Link to="/traveldetails2" className="btn">
                                        order now
                                    </Link>
                                </div>
                            </Col>

                            <Col sm={3}>
                                <div className="subcri">
                                    <h3>FLIGHT + HOTEL</h3>
                                    <h4>MOST LOVED</h4>
                                    <div className="green-row">
                                        <h2>$60</h2>
                                        <span>Per Person</span>
                                    </div>
                                    <span className="hours">24 Hours Delivery</span>
                                    <ul>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Unlimited Flights
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Upto 3 Hotels; Option to add more
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Unlimited Corrections
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Stay Upto 3 Weeks/Hotel
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Free Cancellations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                No Obligations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Free Cover & NOC Letter
                                            </Link>
                                        </li>
                                    </ul>
                                    <Link to="/traveldetails3" className="btn">
                                        order now
                                    </Link>
                                </div>
                            </Col>

                            <Col sm={3}>
                                <div className="subcri">
                                    <h3>FLIGHT + HOTEL + INSURANCE</h3>
                                    <h4>ALL COUNTRY VISAS</h4>
                                    <div className="green-row">
                                        <h2>$110</h2>
                                        <span>Per Person</span>
                                    </div>
                                    <span className="hours">24 Hours Delivery</span>
                                    <ul>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Unlimited Flights
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Upto 3 Hotels; Option to add more
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Unlimited Corrections
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Valid Travel Insurance
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Stay Upto 3 Weeks/Hotel{" "}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Free Cancellations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                No Obligations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                $0 deductible, $50,000 coverage
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Free Cover & NOC Letter
                                            </Link>
                                        </li>
                                    </ul>
                                    <Link to="/traveldetails4" className="btn">
                                        order now
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="longer-tab" title="6 Hr Email Delivery">
                        <Row>
                            <Col sm={3}>
                                <div className="subcri">
                                    <h3>FLIGHT ITINERARY</h3>
                                    <h4>ALL COUNTRY VISAS</h4>
                                    <div className="green-row">
                                        <h2>$60</h2>
                                        <span>Per Person</span>
                                    </div>
                                    <span className="hours">6 Hours Delivery</span>
                                    <ul>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Unlimited Flights
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Upto 4 Corrections
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Free Cancellations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                No Obligations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Free Cover & NOC Letter{" "}
                                            </Link>
                                        </li>
                                    </ul>
                                    <Link to="/traveldetails5" className="btn">
                                        order now
                                    </Link>
                                </div>
                            </Col>

                            <Col sm={3}>
                                <div className="subcri">
                                    <h3>HOTEL BOOKING</h3>
                                    <h4>ALL COUNTRY VISAS</h4>
                                    <div className="green-row">
                                        <h2>$65</h2>
                                        <span>Per Person</span>
                                    </div>
                                    <span className="hours">6 Hours Delivery</span>
                                    <ul>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Upto 3 Hotels; Option to add more
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Upto 4 Corrections
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Stay Upto 3 Weeks/Hotel
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Free Cancellations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                No Obligations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Free Cover & NOC Letter
                                            </Link>
                                        </li>
                                    </ul>
                                    <Link to="/traveldetails6" className="btn">
                                        order now
                                    </Link>
                                </div>
                            </Col>

                            <Col sm={3}>
                                <div className="subcri">
                                    <h3>FLIGHT + HOTEL</h3>
                                    <h4>MOST LOVED</h4>
                                    <div className="green-row">
                                        <h2>$80</h2>
                                        <span>Per Person</span>
                                    </div>
                                    <span className="hours">6Hours Delivery</span>
                                    <ul>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Unlimited Flights
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Upto 3 Hotels; Option to add more
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Unlimited Corrections
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Stay Upto 3 Weeks/Hotel
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Free Cancellations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                No Obligations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Free Cover & NOC Letter
                                            </Link>
                                        </li>
                                    </ul>
                                    <Link to="/traveldetails7" className="btn">
                                        order now
                                    </Link>
                                </div>
                            </Col>

                            <Col sm={3}>
                                <div className="subcri">
                                    <h3>FLIGHT + HOTEL + INSURANCE</h3>
                                    <h4>ALL COUNTRY VISAS</h4>
                                    <div className="green-row">
                                        <h2>$130</h2>
                                        <span>Per Person</span>
                                    </div>
                                    <span className="hours">6 Hours Delivery</span>
                                    <ul>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Unlimited Flights
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Upto 3 Hotels; Option to add more
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Unlimited Corrections
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Valid Travel Insurance
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Stay Upto 3 Weeks/Hotel{" "}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Free Cancellations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                No Obligations
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                $0 deductible, $50,000 coverage
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/">
                                                <span>
                                                    <FaCheck />
                                                </span>
                                                Free Cover & NOC Letter
                                            </Link>
                                        </li>
                                    </ul>
                                    <Link to="#" className="btn">
                                        order now
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </Container>
        </Wrapper>
    );
};

export default Subscription;

const Wrapper = styled.section`
  padding: 30px 0 60px;
  .nav-tabs {
    width: 500px;
    margin: auto;
    border: none;
    padding: 40px 0px;
    .nav-link {
      width: 226px;
      height: 40px;
      border-radius: 100px;
      background: #fff;
      color: #079561;
      border: none;
      border: 1px solid #079561;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      margin-right: 10px;
      margin-bottom: 15px;
    }

    .active {
      background: #079561;
      color: #fff;
    }
  }

  .subcri {
    background: #ffffff;
    box-shadow: #6060601a 0px 1px 10px;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    padding: 25px;
    display: flex;
    flex-direction: column;
    height: 100%;
    h3 {
      font-size: 28px;
      font-weight: 400;
      line-height: 32px;
      font-family: poppins;
      color: #1c1c1c;
      text-transform: uppercase;
      text-align: center;
    }
    h4 {
      font-size: 17px;
      line-height: 20px;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 20px;
    }
    .green-row {
      background: #2d9e63;
      color: #ffffff;
      margin: 0 -10px 30px;
      padding: 15px 30px;

      h2 {
        font-size: 35px;
        font-weight: 700;
        line-height: 30px;
        font-family: poppins;
        color: #ffffff;
        margin: 0;
        text-align: center;
        margin-bottom: 5px;
      }
      span {
        font-size: 14px;
        line-height: 26px;
        font-weight: 500;
        margin: 0;
        text-align: center;
        display: block;
      }
    }
    .hours {
      font-size: 17px;
      line-height: 35px;
      font-weight: 600;
      margin: 0;
      padding: 0;
      color: black;
      animation: tabb.8s;
    }
    ul {
      padding: 0px;
      margin: 0px;
    }
    li {
      list-style: none;
      position: relative;
      a {
        color: #2a293e;
        font-size: 16px;
        letter-spacing: 1px;
        padding: 7px 0 7px 0;
        justify-items: start;
        display: flex;
        text-decoration: none;
        font-family: "Poppins";
        span {
          padding-right: 6px;
          color: #2d9e63;
        }
      }
    }

    .btn {
      font-size: 15px;
      background-color: #cef2e9;
      color: #4ca56b;
      padding: 11px 46px;
      border-radius: 50px;
      border: none;
      text-transform: uppercase;
      display: inline-block;
      min-height: 47px;
      font-weight: 600;
      width: 100%;
      margin-top: auto;
    }
  }
  .nav-justified .nav-item,
  .nav-justified > .nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    .col-sm-3 {
      flex: 0 0 auto;
      width: 50%;
      margin-bottom: 20px;
    }
    .nav-tabs {
      width: 230px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    .nav-tabs {
      padding: 10px 0px;
    }
    .col-sm-3 {
      width: 100%;
    }
    .subcri {
      .btn {
        margin-top: 20px;
      }

      h4 {
        text-align: center;
      }
      .green-row {
        margin: 0px 0px 30px;
        h2 {
          text-align: center;
        }
      }
      .green-row {
        span {
          text-align: center;
          display: block;
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 25px 0;
  }
`;
