import React from 'react'
import FillDetails from "../components/TravelDetails5/FillDetails"
import FlightReservation from '../components/TravelDetails5/FlightReservation'
import TravelersDetails from '../components/TravelDetails5/TravelersDetails'
import GeneralInformation from '../components/TravelDetails5/GeneralInformation'
import HotelDetail from '../components/TravelDetails5/HotelDetail'
import InsuranceInformation from '../components/TravelDetails5/InsuranceInformation'
import TravelGuides from "../components/TravelDetails5/TravelGuides"
import SchengenVisa from "../components/TravelDetails5/SchengenVisa"
import VisaApplication from "../components/TravelDetails5/VisaApplication"
import OrderSummary from "../components/TravelDetails5/OrderSummary"
import PaymentMethod from "../components/TravelDetails5/PaymentMethod";



const TravelDetails3 = () => {
    return (
        <>
            <FillDetails />
            <FlightReservation />
            <TravelersDetails />
            <GeneralInformation />
            <HotelDetail />
            <InsuranceInformation />
            <TravelGuides />
            <SchengenVisa />
            <VisaApplication />
            <OrderSummary />
            <PaymentMethod />


        </>
    )
}

export default TravelDetails3