import React from 'react'
import { styled } from 'styled-components'
import { Container, Row, Col, Form } from 'react-bootstrap'


const FlightDetails = () => {
    return (
        <Wrapper>
            <Container>
            <div className='travel-input'>
                <Row>
                    <h2 className="text-center mb-4">Flight Details</h2>
                    <Col md={12}>
                    <div class="gfield_description">Do You Need A Flight Booking For Each Traveler As Well?</div>
                        <Form.Label>Proof of flight reservation is mandatory for Schengen visa. We take care of all bookings and cancellations for your visa application needs.</Form.Label>
                        

                        <Form>
                            {['radio'].map((type) => (
                                <div key={`inline-${type}`} className="mb-3 mt-2">
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-1`}
                                    />
                                    <Form.Check
                                        inline
                                        label="No"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-2`}
                                    />
                                </div>
                            ))}
                        </Form>
                        
                    </Col>
                    <Form.Group as={Col} md="12" controlId="validationCustomUsername">

                                        <div className="gfield_description">Provide Travelers Flight Details:*</div>
                                        <Form.Label>City - Checkin date - Checkout date</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Departing on June 1, 2017 from Los Angeles(LAX) to Frankfurt(FRA); Returning on June 10, 2017 from Frankfurt(FRA) to Los Angeles(LAX) or Type &quot;Please see attachment&quot;"
                                            style={{ height: '200px' }}
                                        />
                                    </Form.Group>
                                    <Col sm={12}>
                                        <div className="flight-prize">
                                        <div className="flight-p">Your Discounted Hotel Price Is: <br />
                                                <div className="gfield_description">(Upto 3 hotels only. Additional hotels @ $10/hotel. Max stay upto 3 weeks/hotel)</div>
                                            </div>
                                            <div className="total-value">$40</div>
                                        </div>
                                    </Col>


                </Row>
                </div>
            </Container>
        </Wrapper>
    )
}

export default FlightDetails
const Wrapper = styled.section`
    font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
    .travel-input {
	padding: 50px;
	background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    }
    .flight-prize {
      display: flex;
      justify-content: space-between;
      font-size: 25px;
      font-weight: 500;
      position: relative;
      }

      .flight-p {
      padding: 7px;
      &: before {content: "";
      position: absolute;
      width: 60%;
      height: 3px;
      top: 30px;
      text-align: center;
      left: 415px;
      border: 2px dotted #069460;
  }
  }
  .total-value {
text-transform: uppercase;
font-size: 35px;
font-weight: 700;
font-family: "Poppins";
color: #069460;
}
 .gfield_description {
  font-size: 14px;
  font-weight: 500;
}
    
@media (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
    .flight-p {
        padding: 7px;
        &: before
         {
            display:none;
       
    }
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.medium}) { 
        .travel-input {
       padding: 15px;
       
    }
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.small}) { 
        .travel-input {
        .flight-prize {
            font-size:19px;
            display:block;
            .total-value {
                font-size: 35px;
                color: #069460;
                text-align: center;
                background: #bbf3e0;
            }
            
        }
        
    }
`;