import { useState } from 'react';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';



const VisaAppointment = () => {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };
    return (
        <Wrapper>
            <Container>
                <div className='travel-input'>
                    <Row>
                        <Col sm={12}>
                            <h2 className="text-center mb-4">Book A Visa Appointment<p className="text-post">Not sure where and how to book an earliest visa appointment slot? Don't waste your valuable time. Leave it up to us! Just let us know your appointment preferences and we will use our resources and expertise to help you secure a slot that works best for you. It's that simple! Only ONE appointment is allowed PER traveler. The service fee is per traveler. Group discounts are available for more than one traveler!</p></h2>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Col md={12}>

                                        <Form.Label>Pls note that you can make an appointment with the consulate on your own for FREE. Using our services, you confirm that you need our help and assistance with booking an appointment on your behalf for a small service fee.*</Form.Label>


                                        <Form>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`} className="mb-3 mt-2">
                                                    <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name="group1"
                                                        type={type}
                                                        id={`inline-${type}-1`}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="No"
                                                        name="group1"
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                    />
                                                </div>
                                            ))}
                                        </Form>

                                    </Col>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustom01">


                                        <Form.Label>No. Of Travelers Requiring Visa Appointment*</Form.Label>
                                        <Form.Select aria-label="Default select example">
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                            <option>7</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustom02">
                                        <Form.Label>Visa Applying For*</Form.Label>
                                        <Form.Select aria-label="Default select example">
                                            <option value="" selected="selected" class="gf_placeholder">Please Select</option>
                                            <option value="Australia Visa">Australia Visa</option>
                                            <option value="Austria Visa">Austria Visa</option>
                                            <option value="Belgium Visa">Belgium Visa</option>
                                            <option value="Brazil Visa">Brazil Visa</option>
                                            <option value="Canada Visa">Canada Visa</option>
                                            <option value="China Visa">China Visa</option>
                                            <option value="Croatia Visa">Croatia Visa</option>
                                            <option value="Cyprus Visa">Cyprus Visa</option>
                                            <option value="Czech Republic Visa">Czech Republic Visa</option>
                                            <option value="Denmark Visa">Denmark Visa</option>
                                            <option value="Estonia Visa">Estonia Visa</option>
                                            <option value="Faroe Islands Visa">Faroe Islands Visa</option>
                                            <option value="Finland Visa">Finland Visa</option>
                                            <option value="France Visa">France Visa</option>
                                            <option value="Georgia Visa">Georgia Visa</option>
                                            <option value="Germany Visa">Germany Visa</option>
                                            <option value="Ghana Visa">Ghana Visa</option>
                                            <option value="Greece Visa">Greece Visa</option>
                                            <option value="Greenland Visa">Greenland Visa</option>
                                            <option value="Hungary Visa">Hungary Visa</option>
                                            <option value="Iceland Visa">Iceland Visa</option>
                                            <option value="India Visa">India Visa</option>
                                            <option value="Ireland Visa">Ireland Visa</option>
                                            <option value="Italy Visa">Italy Visa</option>
                                            <option value="Japan Visa">Japan Visa</option>
                                            <option value="Kingdom of Saudi Arabia Visa">Kingdom of Saudi Arabia Visa</option><option value="Latvia Visa">Latvia Visa</option>
                                            <option value="Liechtenstein Visa">Liechtenstein Visa</option>
                                            <option value="Lithuania Visa">Lithuania Visa</option>
                                            <option value="Luxembourg Visa">Luxembourg Visa</option>
                                            <option value="Malta Visa">Malta Visa</option>
                                            <option value="Monaco Visa">Monaco Visa</option>
                                            <option value="New Zealand Visa">New Zealand Visa</option>
                                            <option value="Nigeria Visa">Nigeria Visa</option>
                                            <option value="Norway Visa">Norway Visa</option>
                                            <option value="Poland Visa">Poland Visa</option>
                                            <option value="Portugal Visa">Portugal Visa</option>
                                            <option value="Russia Visa">Russia Visa</option>
                                            <option value="Schengen Visa">Schengen Visa</option>
                                            <option value="Singapore Visa">Singapore Visa</option>
                                            <option value="Slovakia Visa">Slovakia Visa</option>
                                            <option value="Slovenia Visa">Slovenia Visa</option>
                                            <option value="South Africa Visa">South Africa Visa</option>
                                            <option value="Spain Visa">Spain Visa</option>
                                            <option value="Sweden Visa">Sweden Visa</option>
                                            <option value="Switzerland Visa">Switzerland Visa</option>
                                            <option value="Netherlands Visa">Netherlands Visa</option>
                                            <option value="Turkey Visa">Turkey Visa</option>
                                            <option value="Ukraine Visa">Ukraine Visa</option>
                                            <option value="United States of America Visa">United States of America Visa</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustomUsername">
                                        <Form.Label>Country Travelling To?*</Form.Label>
                                        <Form.Select aria-label="Default select example">
                                            <option value="" selected="selected" class="gf_placeholder">Please Select</option>
                                            <option value="Australia Visa">Australia Visa</option>
                                            <option value="Austria Visa">Austria Visa</option>
                                            <option value="Belgium Visa">Belgium Visa</option>
                                            <option value="Brazil Visa">Brazil Visa</option>
                                            <option value="Canada Visa">Canada Visa</option>
                                            <option value="China Visa">China Visa</option>
                                            <option value="Croatia Visa">Croatia Visa</option>
                                            <option value="Cyprus Visa">Cyprus Visa</option>
                                            <option value="Czech Republic Visa">Czech Republic Visa</option>
                                            <option value="Denmark Visa">Denmark Visa</option>
                                            <option value="Estonia Visa">Estonia Visa</option>
                                            <option value="Faroe Islands Visa">Faroe Islands Visa</option>
                                            <option value="Finland Visa">Finland Visa</option>
                                            <option value="France Visa">France Visa</option>
                                            <option value="Georgia Visa">Georgia Visa</option>
                                            <option value="Germany Visa">Germany Visa</option>
                                            <option value="Ghana Visa">Ghana Visa</option>
                                            <option value="Greece Visa">Greece Visa</option>
                                            <option value="Greenland Visa">Greenland Visa</option>
                                            <option value="Hungary Visa">Hungary Visa</option>
                                            <option value="Iceland Visa">Iceland Visa</option>
                                            <option value="India Visa">India Visa</option>
                                            <option value="Ireland Visa">Ireland Visa</option>
                                            <option value="Italy Visa">Italy Visa</option>
                                            <option value="Japan Visa">Japan Visa</option>
                                            <option value="Kingdom of Saudi Arabia Visa">Kingdom of Saudi Arabia Visa</option><option value="Latvia Visa">Latvia Visa</option>
                                            <option value="Liechtenstein Visa">Liechtenstein Visa</option>
                                            <option value="Lithuania Visa">Lithuania Visa</option>
                                            <option value="Luxembourg Visa">Luxembourg Visa</option>
                                            <option value="Malta Visa">Malta Visa</option>
                                            <option value="Monaco Visa">Monaco Visa</option>
                                            <option value="New Zealand Visa">New Zealand Visa</option>
                                            <option value="Nigeria Visa">Nigeria Visa</option>
                                            <option value="Norway Visa">Norway Visa</option>
                                            <option value="Poland Visa">Poland Visa</option>
                                            <option value="Portugal Visa">Portugal Visa</option>
                                            <option value="Russia Visa">Russia Visa</option>
                                            <option value="Schengen Visa">Schengen Visa</option>
                                            <option value="Singapore Visa">Singapore Visa</option>
                                            <option value="Slovakia Visa">Slovakia Visa</option>
                                            <option value="Slovenia Visa">Slovenia Visa</option>
                                            <option value="South Africa Visa">South Africa Visa</option>
                                            <option value="Spain Visa">Spain Visa</option>
                                            <option value="Sweden Visa">Sweden Visa</option>
                                            <option value="Switzerland Visa">Switzerland Visa</option>
                                            <option value="Netherlands Visa">Netherlands Visa</option>
                                            <option value="Turkey Visa">Turkey Visa</option>
                                            <option value="Ukraine Visa">Ukraine Visa</option>
                                            <option value="United States of America Visa">United States of America Visa</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustom01">


                                        <Form.Label>Country You Need The Appointment IN?*</Form.Label>
                                        <Form.Select aria-label="Default select example">

                                           <option value="" selected="selected" class="gf_placeholder">Please Select</option><option value="Algeria">Algeria</option>
                                           <option value="Angola">Angola</option>
                                           <option value="Argentina">Argentina</option>
                                           <option value="Armenia">Armenia</option>
                                           <option value="Australia">Australia</option>
                                           <option value="Austria">Austria</option>
                                           <option value="Azerbaijan">Azerbaijan</option>
                                           <option value="Bahrain">Bahrain</option>
                                           <option value="Bangladesh">Bangladesh</option>
                                           <option value="Belarus">Belarus</option>
                                           <option value="Belgium">Belgium</option>
                                           <option value="Benin">Benin</option>
                                           <option value="Bhutan">Bhutan</option>
                                           <option value="Bolivia">Bolivia</option>
                                           <option value="Botswana">Botswana</option>
                                           <option value="Brunei">Brunei</option>
                                           <option value="Burkina Faso">Burkina Faso</option>
                                           <option value="Burundi">Burundi</option><option value="Cambodia">Cambodia</option>
                                           <option value="Cameroon">Cameroon</option>
                                           <option value="Canada">Canada</option>
                                           <option value="Cape Verde Islands">Cape Verde Islands</option>
                                           <option value="Central African Republic">Central African Republic</option>
                                           <option value="Chad">Chad</option>
                                           <option value="China">China</option>
                                           <option value="Colombia">Colombia</option>
                                           <option value="Congo">Congo</option>
                                           <option value="Democratic Republic of Congo">Democratic Republic of Congo</option><option value="Djibouti">Djibouti</option>
                                           <option value="Dominican Republic">Dominican Republic</option>
                                           <option value="Ecuador">Ecuador</option>
                                           <option value="Egypt">Egypt</option>
                                           <option value="Equatorial Guinea">Equatorial Guinea</option>
                                           <option value="Eritrea">Eritrea</option>
                                           <option value="Estonia">Estonia</option>
                                           <option value="Ethiopia">Ethiopia</option>
                                           <option value="Fiji">Fiji</option>
                                           <option value="Gabon">Gabon</option>
                                           <option value="Gambia">Gambia</option>
                                           <option value="Germany">Germany</option>
                                           <option value="Ghana">Ghana</option>
                                           <option value="Greece">Greece</option>
                                           <option value="Guinea">Guinea</option>
                                           <option value="Guinea Bissau">Guinea Bissau</option>
                                           <option value="Haiti">Haiti</option>
                                           <option value="Hong Kong">Hong Kong</option>
                                           <option value="India">India</option>
                                           <option value="Indonesia">Indonesia</option>
                                           <option value="Iran">Iran</option>
                                           <option value="Iraq">Iraq</option>
                                           <option value="Israel">Israel</option>
                                           <option value="Italy">Italy</option>
                                           <option value="Ivory Coast">Ivory Coast</option>
                                           <option value="Jamaica">Jamaica</option>
                                           <option value="Japan">Japan</option>
                                           <option value="Jordan">Jordan</option>
                                           <option value="Kazakhstan">Kazakhstan</option>
                                           <option value="Kenya">Kenya</option>
                                           <option value="Kingdom of Saudi Arabia">Kingdom of Saudi Arabia</option>
                                           <option value="Kosovo">Kosovo</option>
                                           <option value="Kuwait">Kuwait</option>
                                           <option value="Latvia">Latvia</option>
                                           <option value="Lebanon">Lebanon</option>
                                           <option value="Lesotho">Lesotho</option>
                                           <option value="Liberia">Liberia</option>
                                           <option value="Libya">Libya</option>
                                           <option value="Madagascar">Madagascar</option>
                                           <option value="Malawi">Malawi</option>
                                           <option value="Malaysia">Malaysia</option>
                                           <option value="Maldives">Maldives</option>
                                           <option value="Mali">Mali</option>
                                           <option value="Mauritania">Mauritania</option>
                                           <option value="Mauritius">Mauritius</option>
                                           <option value="Mexico">Mexico</option>
                                           <option value="Mongolia">Mongolia</option>
                                           <option value="Morocco">Morocco</option>
                                           <option value="Mozambique">Mozambique</option>
                                           <option value="Myanmar">Myanmar</option>
                                           <option value="Namibia">Namibia</option>
                                           <option value="Nepal">Nepal</option>
                                           <option value="Netherlands">Netherlands</option>
                                           <option value="New Zealand">New Zealand</option>
                                           <option value="Niger">Niger</option>
                                           <option value="Nigeria">Nigeria</option>
                                           <option value="Oman">Oman</option>
                                           <option value="Pakistan">Pakistan</option>
                                           <option value="Palestinian Territories">Palestinian Territories</option>
                                           <option value="Philippines">Philippines</option>
                                           <option value="Poland">Poland</option>
                                           <option value="Portugal">Portugal</option>
                                           <option value="Qatar">Qatar</option>
                                           <option value="Republic of Korea">Republic of Korea</option>
                                           <option value="Republic of Sudan">Republic of Sudan</option>
                                           <option value="Romania">Romania</option>
                                           <option value="Russia">Russia</option>
                                           <option value="Rwanda">Rwanda</option>
                                           <option value="Sao Tome Principe">Sao Tome Principe</option>
                                           <option value="Senegal">Senegal</option>
                                           <option value="Sierra Leone">Sierra Leone</option>
                                           <option value="Singapore">Singapore</option>
                                           <option value="Somalia">Somalia</option>
                                           <option value="South Africa">South Africa</option>
                                           <option value="South Sudan">South Sudan</option>
                                           <option value="Sri Lanka">Sri Lanka</option>
                                           <option value="Suriname">Suriname</option>
                                           <option value="Swaziland">Swaziland</option>
                                           <option value="Sweden">Sweden</option>
                                           <option value="Switzerland">Switzerland</option><option value="Tajikistan">Tajikistan</option>
                                           <option value="Tanzania">Tanzania</option>
                                           <option value="Thailand">Thailand</option>
                                           <option value="Togo">Togo</option>
                                           <option value="Tunisia">Tunisia</option>
                                           <option value="Turkey">Turkey</option>
                                           <option value="Uganda">Uganda</option>
                                           <option value="Ukraine">Ukraine</option><option value="United Arab Emirates">United Arab Emirates</option><option value="United Kingdom">United Kingdom</option>
                                           <option value="United States of America">United States of America</option>
                                           <option value="Uruguay">Uruguay</option>
                                           <option value="Venezuela">Venezuela</option><option value="Vietnam">Vietnam</option>
                                           <option value="Zambia">Zambia</option>
                                           <option value="Zimbabwe">Zimbabwe</option> 
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustom02">
                                        <Form.Label>City You Need The Visa Appointment IN?*</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Houston"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustomUsername">
                                        <Form.Label>Departing Country*?</Form.Label>
                                        <Form.Select aria-label="Default select example">

                                           <option value="" selected="selected" class="gf_placeholder">Please Select</option><option value="Algeria">Algeria</option>
                                           <option value="Angola">Angola</option>
                                           <option value="Argentina">Argentina</option>
                                           <option value="Armenia">Armenia</option>
                                           <option value="Australia">Australia</option>
                                           <option value="Austria">Austria</option>
                                           <option value="Azerbaijan">Azerbaijan</option>
                                           <option value="Bahrain">Bahrain</option>
                                           <option value="Bangladesh">Bangladesh</option>
                                           <option value="Belarus">Belarus</option>
                                           <option value="Belgium">Belgium</option>
                                           <option value="Benin">Benin</option>
                                           <option value="Bhutan">Bhutan</option>
                                           <option value="Bolivia">Bolivia</option>
                                           <option value="Botswana">Botswana</option>
                                           <option value="Brunei">Brunei</option>
                                           <option value="Burkina Faso">Burkina Faso</option>
                                           <option value="Burundi">Burundi</option><option value="Cambodia">Cambodia</option>
                                           <option value="Cameroon">Cameroon</option>
                                           <option value="Canada">Canada</option>
                                           <option value="Cape Verde Islands">Cape Verde Islands</option>
                                           <option value="Central African Republic">Central African Republic</option>
                                           <option value="Chad">Chad</option>
                                           <option value="China">China</option>
                                           <option value="Colombia">Colombia</option>
                                           <option value="Congo">Congo</option>
                                           <option value="Democratic Republic of Congo">Democratic Republic of Congo</option><option value="Djibouti">Djibouti</option>
                                           <option value="Dominican Republic">Dominican Republic</option>
                                           <option value="Ecuador">Ecuador</option>
                                           <option value="Egypt">Egypt</option>
                                           <option value="Equatorial Guinea">Equatorial Guinea</option>
                                           <option value="Eritrea">Eritrea</option>
                                           <option value="Estonia">Estonia</option>
                                           <option value="Ethiopia">Ethiopia</option>
                                           <option value="Fiji">Fiji</option>
                                           <option value="Gabon">Gabon</option>
                                           <option value="Gambia">Gambia</option>
                                           <option value="Germany">Germany</option>
                                           <option value="Ghana">Ghana</option>
                                           <option value="Greece">Greece</option>
                                           <option value="Guinea">Guinea</option>
                                           <option value="Guinea Bissau">Guinea Bissau</option>
                                           <option value="Haiti">Haiti</option>
                                           <option value="Hong Kong">Hong Kong</option>
                                           <option value="India">India</option>
                                           <option value="Indonesia">Indonesia</option>
                                           <option value="Iran">Iran</option>
                                           <option value="Iraq">Iraq</option>
                                           <option value="Israel">Israel</option>
                                           <option value="Italy">Italy</option>
                                           <option value="Ivory Coast">Ivory Coast</option>
                                           <option value="Jamaica">Jamaica</option>
                                           <option value="Japan">Japan</option>
                                           <option value="Jordan">Jordan</option>
                                           <option value="Kazakhstan">Kazakhstan</option>
                                           <option value="Kenya">Kenya</option>
                                           <option value="Kingdom of Saudi Arabia">Kingdom of Saudi Arabia</option>
                                           <option value="Kosovo">Kosovo</option>
                                           <option value="Kuwait">Kuwait</option>
                                           <option value="Latvia">Latvia</option>
                                           <option value="Lebanon">Lebanon</option>
                                           <option value="Lesotho">Lesotho</option>
                                           <option value="Liberia">Liberia</option>
                                           <option value="Libya">Libya</option>
                                           <option value="Madagascar">Madagascar</option>
                                           <option value="Malawi">Malawi</option>
                                           <option value="Malaysia">Malaysia</option>
                                           <option value="Maldives">Maldives</option>
                                           <option value="Mali">Mali</option>
                                           <option value="Mauritania">Mauritania</option>
                                           <option value="Mauritius">Mauritius</option>
                                           <option value="Mexico">Mexico</option>
                                           <option value="Mongolia">Mongolia</option>
                                           <option value="Morocco">Morocco</option>
                                           <option value="Mozambique">Mozambique</option>
                                           <option value="Myanmar">Myanmar</option>
                                           <option value="Namibia">Namibia</option>
                                           <option value="Nepal">Nepal</option>
                                           <option value="Netherlands">Netherlands</option>
                                           <option value="New Zealand">New Zealand</option>
                                           <option value="Niger">Niger</option>
                                           <option value="Nigeria">Nigeria</option>
                                           <option value="Oman">Oman</option>
                                           <option value="Pakistan">Pakistan</option>
                                           <option value="Palestinian Territories">Palestinian Territories</option>
                                           <option value="Philippines">Philippines</option>
                                           <option value="Poland">Poland</option>
                                           <option value="Portugal">Portugal</option>
                                           <option value="Qatar">Qatar</option>
                                           <option value="Republic of Korea">Republic of Korea</option>
                                           <option value="Republic of Sudan">Republic of Sudan</option>
                                           <option value="Romania">Romania</option>
                                           <option value="Russia">Russia</option>
                                           <option value="Rwanda">Rwanda</option>
                                           <option value="Sao Tome Principe">Sao Tome Principe</option>
                                           <option value="Senegal">Senegal</option>
                                           <option value="Sierra Leone">Sierra Leone</option>
                                           <option value="Singapore">Singapore</option>
                                           <option value="Somalia">Somalia</option>
                                           <option value="South Africa">South Africa</option>
                                           <option value="South Sudan">South Sudan</option>
                                           <option value="Sri Lanka">Sri Lanka</option>
                                           <option value="Suriname">Suriname</option>
                                           <option value="Swaziland">Swaziland</option>
                                           <option value="Sweden">Sweden</option>
                                           <option value="Switzerland">Switzerland</option><option value="Tajikistan">Tajikistan</option>
                                           <option value="Tanzania">Tanzania</option>
                                           <option value="Thailand">Thailand</option>
                                           <option value="Togo">Togo</option>
                                           <option value="Tunisia">Tunisia</option>
                                           <option value="Turkey">Turkey</option>
                                           <option value="Uganda">Uganda</option>
                                           <option value="Ukraine">Ukraine</option><option value="United Arab Emirates">United Arab Emirates</option><option value="United Kingdom">United Kingdom</option>
                                           <option value="United States of America">United States of America</option>
                                           <option value="Uruguay">Uruguay</option>
                                           <option value="Venezuela">Venezuela</option><option value="Vietnam">Vietnam</option>
                                           <option value="Zambia">Zambia</option>
                                           <option value="Zimbabwe">Zimbabwe</option> 
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustom01">


                                        <Form.Label>Arrival Country*</Form.Label>
                                        <Form.Select aria-label="Default select example">

                                           <option value="" selected="selected" class="gf_placeholder">Please Select</option><option value="Algeria">Algeria</option>
                                           <option value="Angola">Angola</option>
                                           <option value="Argentina">Argentina</option>
                                           <option value="Armenia">Armenia</option>
                                           <option value="Australia">Australia</option>
                                           <option value="Austria">Austria</option>
                                           <option value="Azerbaijan">Azerbaijan</option>
                                           <option value="Bahrain">Bahrain</option>
                                           <option value="Bangladesh">Bangladesh</option>
                                           <option value="Belarus">Belarus</option>
                                           <option value="Belgium">Belgium</option>
                                           <option value="Benin">Benin</option>
                                           <option value="Bhutan">Bhutan</option>
                                           <option value="Bolivia">Bolivia</option>
                                           <option value="Botswana">Botswana</option>
                                           <option value="Brunei">Brunei</option>
                                           <option value="Burkina Faso">Burkina Faso</option>
                                           <option value="Burundi">Burundi</option><option value="Cambodia">Cambodia</option>
                                           <option value="Cameroon">Cameroon</option>
                                           <option value="Canada">Canada</option>
                                           <option value="Cape Verde Islands">Cape Verde Islands</option>
                                           <option value="Central African Republic">Central African Republic</option>
                                           <option value="Chad">Chad</option>
                                           <option value="China">China</option>
                                           <option value="Colombia">Colombia</option>
                                           <option value="Congo">Congo</option>
                                           <option value="Democratic Republic of Congo">Democratic Republic of Congo</option><option value="Djibouti">Djibouti</option>
                                           <option value="Dominican Republic">Dominican Republic</option>
                                           <option value="Ecuador">Ecuador</option>
                                           <option value="Egypt">Egypt</option>
                                           <option value="Equatorial Guinea">Equatorial Guinea</option>
                                           <option value="Eritrea">Eritrea</option>
                                           <option value="Estonia">Estonia</option>
                                           <option value="Ethiopia">Ethiopia</option>
                                           <option value="Fiji">Fiji</option>
                                           <option value="Gabon">Gabon</option>
                                           <option value="Gambia">Gambia</option>
                                           <option value="Germany">Germany</option>
                                           <option value="Ghana">Ghana</option>
                                           <option value="Greece">Greece</option>
                                           <option value="Guinea">Guinea</option>
                                           <option value="Guinea Bissau">Guinea Bissau</option>
                                           <option value="Haiti">Haiti</option>
                                           <option value="Hong Kong">Hong Kong</option>
                                           <option value="India">India</option>
                                           <option value="Indonesia">Indonesia</option>
                                           <option value="Iran">Iran</option>
                                           <option value="Iraq">Iraq</option>
                                           <option value="Israel">Israel</option>
                                           <option value="Italy">Italy</option>
                                           <option value="Ivory Coast">Ivory Coast</option>
                                           <option value="Jamaica">Jamaica</option>
                                           <option value="Japan">Japan</option>
                                           <option value="Jordan">Jordan</option>
                                           <option value="Kazakhstan">Kazakhstan</option>
                                           <option value="Kenya">Kenya</option>
                                           <option value="Kingdom of Saudi Arabia">Kingdom of Saudi Arabia</option>
                                           <option value="Kosovo">Kosovo</option>
                                           <option value="Kuwait">Kuwait</option>
                                           <option value="Latvia">Latvia</option>
                                           <option value="Lebanon">Lebanon</option>
                                           <option value="Lesotho">Lesotho</option>
                                           <option value="Liberia">Liberia</option>
                                           <option value="Libya">Libya</option>
                                           <option value="Madagascar">Madagascar</option>
                                           <option value="Malawi">Malawi</option>
                                           <option value="Malaysia">Malaysia</option>
                                           <option value="Maldives">Maldives</option>
                                           <option value="Mali">Mali</option>
                                           <option value="Mauritania">Mauritania</option>
                                           <option value="Mauritius">Mauritius</option>
                                           <option value="Mexico">Mexico</option>
                                           <option value="Mongolia">Mongolia</option>
                                           <option value="Morocco">Morocco</option>
                                           <option value="Mozambique">Mozambique</option>
                                           <option value="Myanmar">Myanmar</option>
                                           <option value="Namibia">Namibia</option>
                                           <option value="Nepal">Nepal</option>
                                           <option value="Netherlands">Netherlands</option>
                                           <option value="New Zealand">New Zealand</option>
                                           <option value="Niger">Niger</option>
                                           <option value="Nigeria">Nigeria</option>
                                           <option value="Oman">Oman</option>
                                           <option value="Pakistan">Pakistan</option>
                                           <option value="Palestinian Territories">Palestinian Territories</option>
                                           <option value="Philippines">Philippines</option>
                                           <option value="Poland">Poland</option>
                                           <option value="Portugal">Portugal</option>
                                           <option value="Qatar">Qatar</option>
                                           <option value="Republic of Korea">Republic of Korea</option>
                                           <option value="Republic of Sudan">Republic of Sudan</option>
                                           <option value="Romania">Romania</option>
                                           <option value="Russia">Russia</option>
                                           <option value="Rwanda">Rwanda</option>
                                           <option value="Sao Tome Principe">Sao Tome Principe</option>
                                           <option value="Senegal">Senegal</option>
                                           <option value="Sierra Leone">Sierra Leone</option>
                                           <option value="Singapore">Singapore</option>
                                           <option value="Somalia">Somalia</option>
                                           <option value="South Africa">South Africa</option>
                                           <option value="South Sudan">South Sudan</option>
                                           <option value="Sri Lanka">Sri Lanka</option>
                                           <option value="Suriname">Suriname</option>
                                           <option value="Swaziland">Swaziland</option>
                                           <option value="Sweden">Sweden</option>
                                           <option value="Switzerland">Switzerland</option><option value="Tajikistan">Tajikistan</option>
                                           <option value="Tanzania">Tanzania</option>
                                           <option value="Thailand">Thailand</option>
                                           <option value="Togo">Togo</option>
                                           <option value="Tunisia">Tunisia</option>
                                           <option value="Turkey">Turkey</option>
                                           <option value="Uganda">Uganda</option>
                                           <option value="Ukraine">Ukraine</option><option value="United Arab Emirates">United Arab Emirates</option><option value="United Kingdom">United Kingdom</option>
                                           <option value="United States of America">United States of America</option>
                                           <option value="Uruguay">Uruguay</option>
                                           <option value="Venezuela">Venezuela</option><option value="Vietnam">Vietnam</option>
                                           <option value="Zambia">Zambia</option>
                                           <option value="Zimbabwe">Zimbabwe</option> 
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustom02">
                                        <Form.Label>Trip Start Date*</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="dd/mm/yy"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustomUsername">
                                        <Form.Label>Trip End Date*</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="dd/mm/yy"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustom01">


                                        <Form.Label>Purpose Of Appointment*</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Tourism, Study, Business"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4"className='mb-4'  controlId="validationCustom02">
                                        <Form.Label>Choose An Appointment Date*</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="dd/mm/yy"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustomUsername">
                                        <Form.Label>Choose An Appointment Time*</Form.Label>
                                        <Form.Select aria-label="Default select example">

                                        <option>Please Select</option>
                                        <option>6 AM to 9 AM</option>
                                        <option>9 AM to 12 PM</option>
                                        <option>12 PM to 3 PM</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustom01">


                                        <Form.Label>Delivery Email Address*</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="John.rick@gmail.com"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4"className='mb-4'  controlId="validationCustom02">
                                        <Form.Label>Phone No.*</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Country Code + Phone Number"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-4' controlId="validationCustomUsername">
                                        <Form.Label>How Did You Hear About Us?</Form.Label>
                                        <Form.Select aria-label="Default select example">

                                        <option>Please Select</option>
                                        <option>GermanyVisaOrg Website</option>
                                        <option>VisaGuideWorld Website</option>
                                        <option>SchengenVisaInfo Website</option>
                                        <option >Google Ads</option>
                                        <option>Visa Reservation Blog Article</option>
                                        <option>SchengenVisaCommunity Facebook Group</option>
                                        <option>Youtube</option>
                                        <option>Discussion Board</option>
                                        <option>Social Media-Facebook, Twitter</option>
                                        <option>Quora</option>
                                        <option>Friends & Family</option>
                                        <option>Google Search Engine</option>
                                        <option>I'm not sure</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Col md={6}>
                                        <Form.Label>Are You One Of The Travelers Making The Payment? *</Form.Label>
                                        <Form>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`} className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name="group1"
                                                        type={type}
                                                        id={`inline-${type}-1`}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="No"
                                                        name="group1"
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                    />
                                                </div>
                                            ))}
                                        </Form>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="validationCustom02">
                                            <Form.Label>Provide The Name On The Billing Account?*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter your name as you are making the payment"
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Form.Group as={Col} md="12 mt-3" controlId="validationCustomUsername">
                                    <Form.Label>If the appointment is unavailable at the chosen consulate on the chosen date, are you flexible to book an earliest appointment at other applicable consulates or dates?*</Form.Label>

                            <Form>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3 mt-2">
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-1`}
                                        />
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-2`}
                                        />
                                    </div>
                                ))}
                            </Form>
                            </Form.Group>

                            <Form.Group as={Col} md="12 mt-3" controlId="validationCustomUsername">
                            <Form.Label className='mb-2'>Provide your other appointment preferences, in case if the chosen appointment is unavailable*</Form.Label>

                            <Form.Control
                                as="textarea"
                                placeholder="1) German Consulate - New York - Any day from June 15, 2017 to June 30, 2017 between 8 AM and 12 AM"
                                style={{ height: '200px' }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="12 mt-3" controlId="validationCustomUsername">
                        <div className="gfield_description">Provide your trip details*</div>
                            <Form.Label className='mb-2'>Departure date - Departing city/airport - Arrival city/airport</Form.Label>

                            <Form.Control
                                as="textarea"
                                placeholder="Departing on June 1, 2017 from Los Angeles(LAX) to Frankfurt(FRA); Returning on June 10, 2017 from Frankfurt(FRA) to Los Angeles(LAX) or Type &quot;Please see attachment&quot;"
                                style={{ height: '200px' }}
                            />
                        </Form.Group>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Wrapper>
    )
}

export default VisaAppointment
const Wrapper = styled.section`
font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
    .travel-input {
	padding: 50px;
	background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    .interview-tp {
    margin-top: -21px;
}
label{
    margin-bottom:5px;
}
.text-post {
    padding-top: 10px;
    padding-bottom: 30px;
}
    }
   
    @media (max-width: ${({ theme }) => theme.breakpoints.small}) { 
         .travel-input {
        padding: 15px;
        
    }
}

`;