import React from 'react'
import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from 'react-bootstrap/Accordion';
import { styled } from 'styled-components';
import { Link } from 'react-router-dom';


const UsefulTips = () => {
    return (
        <Wrapper>
            <Container>

                <div className='travel-input'>
                    <h2 className="text-center mb-4">Useful Tips</h2>
                    <Accordion defaultKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Info About EU Countries</Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <Link to="#">EU Countries and The European Union</Link>
                                    </p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header> Info About Schengen Countries </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <Link to="#">Schengen Countries List</Link>
                                    </p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>  Download Schengen Visa Application Form </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <Link to="#">Download Schengen Visa Application Form</Link>
                                    </p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>  Book Visa Appointment </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <Link to="#">Book Visa Appointment</Link>
                                    </p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header> Order Schengen Visa Interview Guide  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <Link to="#">Order Schengen Visa Interview Questions Guide</Link>
                                    </p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>  Common Reasons For Visa Rejection  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <Link to="#">Common Reasons For Visa Rejection</Link>
                                    </p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header> Info About Cover Letter  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <Link to="#">Info About Cover Letter</Link>
                                    </p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>  Info About No Objection Certificate(NOC) Letter</Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <Link to="#">NOC Letter</Link>
                                    </p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>  Info About Sponsorship Letter </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <Link to="#">Sponsorship Letter</Link>
                                    </p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9">
                            <Accordion.Header>  Info About Invitation Letter  </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <Link to="#">Invitation Letter</Link>
                                    </p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="10">
                            <Accordion.Header>  Info About Financial Sufficiency </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <Link to="#">Means Of Subsistence</Link>
                                    </p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="11">
                            <Accordion.Header>  Visa Photo Requirements </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>
                                        <Link to="#">Schengen Visa Photo Requirements</Link>
                                    </p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="12">
                            <Accordion.Header>  Consultations </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <p>Apply For Visa</p>
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Container>


        </Wrapper>
    )
}

export default UsefulTips
const Wrapper = styled.section`
font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
.travel-input {
    padding: 50px;
    background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    .accordion-button {
        font-size: 16px;
        font-weight: 500;
       
    }
}
@media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 0px;
}
@media (max-width: ${({ theme }) => theme.breakpoints.small}) { 
    .travel-input {
   padding: 15px;
   
}
}
`;