import React, { useState, useEffect } from "react";
import { Container, Row,Col,Tab,Nav, Button, Form, Dropdown, Table } from 'react-bootstrap'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaHandPointer } from "react-icons/fa6";
import { FaHouseChimney } from "react-icons/fa6";
import axios from "axios";
import { useNavigate } from "react-router-dom";




const AdminDashborad = () => {

  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [client, setClient] = useState("");
  const [review, setReview] = useState("");

  const [file, setFile] = useState()
  const [editfile, setEditFile] = useState()

  const [editimage, setEditImage] = useState("");
  const [edittitle, setEditTitle] = useState("");
  const [editclient, setEditClient] = useState("");
  const [editreview, setEditReview] = useState("");
  const [testimonial_id, setTestimonialId] = useState("");
  const [row_id, setRowId] = useState("");

  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const [inputFields, setInputFields] = useState({
    title: "",
    client: "",
    review: ""
  });

  const validateValues = (inputValues) => {
    console.log(file);
    let errors = {};
    if (inputValues.title.length < 5) {
      errors.title = "Client title should be atleat 5 character";
    }
    if (inputValues.client.length < 3) {
      errors.client = "Client name should be atleat 3 character";
    }
    if (inputValues.review.length < 15) {
      errors.review = "Client review should be atleat 15 character";
    }
    if(typeof file === 'undefined') {
      errors.image = "Client image is required";
    }
    return errors;
  };


  const handleChangeError = (event) => {
    event.preventDefault();
    setErrors(validateValues(inputFields));
    setSubmitting(true);
  };

  
  const finishSubmit = () => {
    console.log(inputFields);
    handleSubmit(inputFields);
    
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
      
    }
  }, [errors]);

  const uploadedFileURL = "http://localhost:8000/assets/images/client-images/";

  const EditForm = async (a,b,c,d,f,i,) => {   
    setEditImage(a);
    setEditTitle(b);
    setEditClient(c);
    setEditReview(d);
    setTestimonialId(f);
    setRowId(i);
  }
  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      navigate("/");
    }
  }, []);

  /* testimonial data fetch with API */
  //const url = "https://jsonplaceholder.typicode.com/users";
  const url = "http://localhost:8000/api/testimoniallist";
  const [data, setData] = useState([]);
  
  const fetchInfo = () => {
    return fetch(url)
      .then((res) => res.json())
      .then((d) => setData(d.testimonialData))
  }

  useEffect(() => {
    fetchInfo();
  }, []);
  /*  */

  /* redirect View Testimonial Form */
  const viewTestimonial = () => {        
    document.getElementById('viewlink').click();
  }

  const resetForm = () => {
    setEditImage("");
    setTitle("");
    setClient("");
    setReview("");
    setEditTitle("");
    setEditClient("");
    setEditReview("");
  }

   /* redirect Add Testimonial Form */
  const [scrollingUp, setScrollingUp] = useState(false);
  const addTestimonial = () => {
    resetForm();
    document.getElementById('addlink').click();
    if (!scrollingUp) {
      setScrollingUp(true);
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
      //setTimeout(() => {
        setScrollingUp(false);
        window.scrollTo({ top: 150, behavior: 'smooth' });
     // }, 1000); // Adjust the delay as needed
     
    }
  };

  /* Insert Testimonial request */

  const handleChangeValidation = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };
  function handleChange(event) {
    setFile(event.target.files[0])
  }


  function viewAddTestimonial(){
    document.getElementById("dropdown-basic").onclick = function(){
      //do something
      document.getElementById("add_testimonial").style.display = "block";
    }
  }


  function handleEditImage(event) {
    setEditFile(event.target.files[0])
  }

  const handleSubmit = (event) => {
    //event.preventDefault();

    const testimonialData = {
      title: inputFields.title,
      client: inputFields.client,
      client_review: inputFields.review,
      image: file,
      fileName: file.name,
    };

    axios
      .post("http://localhost:8000/api/addtestimonial", testimonialData, {
        headers: {
          'content-type': 'multipart/form-data',
           withCredentials: true,
        },
      })
      .then((response) => {
        if (response.status == "200") {          
          alert('Testimonial inserted!');
          console.log('Testimonial inserted!');
          var table = document.getElementById("testimonila_list");
          var body = table.tBodies[0];
          var rows = body.rows ;
          var row_length = rows.length +1;
          var row = table.insertRow(row_length);
          var cell1 = row.insertCell(0);
          var cell2 = row.insertCell(1);
          var cell3 = row.insertCell(2);
          var cell4 = row.insertCell(3);
          var cell5 = row.insertCell(4);
          var imageName = uploadedFileURL + response.data.imageName;
      var clientImage = '<img src="'+imageName+ '" alt="Uploaded content">';
          cell1.innerHTML = row_length;
          cell2.innerHTML = clientImage;
          cell3.innerHTML = response.data.title;
          cell4.innerHTML =  response.data.client;
          cell5.innerHTML =  response.data.review; 
        }
      })
      .catch((error) => {
        //console.error(error); // Handle any errors
      });

      
    };
    
    /* redirect Update Testimonial Form */
    const editTestimonial = async (a,b,c,d,f,i,e) => {    
      resetForm();
      EditForm(a,b,c,d,f,i,);
      document.getElementById('editlink').click();
      if (!scrollingUp) {
        setScrollingUp(true);
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        //setTimeout(() => {
          setScrollingUp(false);
          window.scrollTo({ top: 150, behavior: 'smooth' });
       // }, 1000); // Adjust the delay as needed
       
      }
    }

    /* Back to View Testimonila */
      const BackTestimonial = (event) => {
        document.getElementById('viewlink').click();
      }

    /* Update Testimonial request */
    const handleUpdateSubmit = (event) => {
    event.preventDefault();

    const edittestimonialData = {
      testimonial_id: testimonial_id,
      edittitle: edittitle,
      editclient: editclient,
      edit_client_review: editreview,
      image: editfile,
      //fileName: editfile.name,
    };

    
    axios
      .post("http://localhost:8000/api/updatetestimonial", edittestimonialData, {
        headers: {
          'content-type': 'multipart/form-data',
           withCredentials: true,
        },
      })
      .then((response) => {
        if (response.status == "200") {          
          alert('Testimonial updated!');
          console.log('Testimonial inserted!');
          window.location.reload();
         /*  setTimeout(() => {
          }, 2000,); */
          /* document.getElementById('viewlink').click();     
          document.getElementById("testimonila_list").rows[row_id].cells[2].innerHTML = edittitle;
          document.getElementById("testimonila_list").rows[row_id].cells[3].innerHTML = editclient;
          document.getElementById("testimonila_list").rows[row_id].cells[4].innerHTML = editreview;
          document.getElementById("btn_" + testimonial_id).disabled  = true; */
        }
      })
      .catch((error) => {
        console.error(error); // Handle any errors
      });

      
    };
    
    /* Delete request */
    const delTestimonial = async (a,e) => { 
      var txt = "txt";
      if (window.confirm("Are you sure!")) {               
      e.preventDefault();
      const response = await fetch('http://localhost:8000/api/deletetestimonial', {
        method: 'POST',
        body: JSON.stringify({ id: a }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const result = await response.json();
      console.log(result);
      if (result.status == "200") {        
        alert('Record Deleted!');
        document.getElementById(a).remove();
      }
    }
  }
    /*  */
 
  

  return (
    <>
      <Wrapper>
        <Container>
          <Tab.Container id="left-tabs-example" defaultActiveKey="second">
            <Row>
              <Col sm={3} className='text-start'>
                <div className='left-sidebar'>
                  <h4>Admin Settings</h4>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first"><span><FaHouseChimney /></span>HomePage</Nav.Link>
                    </Nav.Item>

                    <Dropdown>
                      <Nav.Item>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" >
                          <span><FaHandPointer /></span>Testimonial</Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="second" id="viewlink">View Testimonials</Dropdown.Item>
                          <Dropdown.Item eventKey="third" id="addlink">Add Testimonial</Dropdown.Item>
                        </Dropdown.Menu>
                      </Nav.Item>
                    </Dropdown>

                  </Nav>
                  <div style={{ display: 'none' }}><Nav.Link eventKey="fourth" id="editlink">Edit Link</Nav.Link></div>
                </div>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Form>
                      <Row><h4>Home Banner</h4></Row>
                      <Row>
                        <Col sm={12} className='mb-3 text-start'>
                          <Form.Label htmlFor="heading">Heading</Form.Label>
                          <Form.Control
                            type="text"
                            id="subHeading"
                            onChange=""
                            aria-describedby="NameHelpBlock"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6} className='mb-3 text-start'>
                          <Form.Label htmlFor="subHeading">Sub Heading</Form.Label>
                          <Form.Control
                            type="text"
                            id="subHeading"
                            aria-describedby="NameHelpBlock"
                          />
                        </Col>
                        <Col sm={6} className='mb-3 text-start'>
                          <Form.Label htmlFor="subHeading">Small Heading</Form.Label>
                          <Form.Control
                            type="text"
                            id="Small Heading"
                            aria-describedby="NameHelpBlock"
                          />
                        </Col>
                      </Row>
                      <Button type="submit" className='custom-btn btn-3'><span>Submit</span></Button>
                    </Form>
                    
                  </Tab.Pane>


                  <Tab.Pane eventKey="second">                  
                  <Col sm={12}>
                  <Row><h4 className='pb-3'>Testimonial List</h4></Row>
                    <Table id="testimonila_list" striped bordered hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Client Image</th>
                          <th>Review Title</th>
                          <th>Client Name</th>
                          <th>Client Review</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {data.map((dataObj, index) => {
                        return (
                          <tr id = {dataObj.id}>
                            <td>{index + 1}</td>
                            <td><img src={uploadedFileURL + dataObj.client_img} alt="Uploaded content"/></td>
                            <td>{dataObj.review_title}</td>
                            <td>{dataObj.client_name}</td>
                            <td>{dataObj.client_review}</td>
                            <td className="text-center">
                              <Button id ={"btn_"+dataObj.id} onClick={(event) => editTestimonial(dataObj.client_img, dataObj.review_title,dataObj.client_name,dataObj.client_review,dataObj.id, index + 1, event)} className='btn-2 edit-btn'><span>Edit</span></Button>
                              <Button onClick={(event) => delTestimonial(dataObj.id, event)} className='btn-2 delete-btn'><span>Delete</span></Button>
                            </td>
                        </tr>
                          );
                      })}                            
                              
                      </tbody>
                    </Table>
                    <center>
                    <Button id="add_testimonial" onClick={addTestimonial} className='btn-2 edit-btn' style={{ display: 'none' }}><span>Add Testimonials</span></Button>
                    </center>
                  </Col> 
                  </Tab.Pane>

                  <Tab.Pane eventKey="third">
                    <form onSubmit={handleChangeError}>
                    <Row><h4 className='pb-3'>Testimonial</h4></Row>
                 <Row>
                    {/* Client Image */}
                    <Col sm={6} className='mb-3'>
                        <Form.Label htmlFor="image">Client Image</Form.Label>
                        <Form.Control
                          type="file"
                          id="image"
                          aria-describedby="NameHelpBlock"
                          onChange={handleChange}
                        />
                        {errors.image ? (<p className="error">{errors.image} </p>) : null}
                      </Col>
                      
                    <Col sm={12} className='mb-3'>
                    <Form.Label htmlFor="subHeading">Title</Form.Label>
                        <Form.Control
                          type="text"
                          id="Title"
                          name="title"
                          aria-describedby="NameHelpBlock"
                          value={inputFields.title}
                        onChange={handleChangeValidation}
                        />
                        {errors.title ? (<p className="error">{errors.title} </p>) : null}
                    </Col>
                    <Col sm={6} className='mb-3'>
                    <Form.Label htmlFor="subHeading">Client Name</Form.Label>
                        <Form.Control
                          type="text"
                          id="client"
                          name="client"
                          aria-describedby="NameHelpBlock"
                          value={inputFields.client}
                          onChange={handleChangeValidation}
                        />
                        {errors.client ? (<p className="error">{errors.client} </p>) : null}
                    </Col>
                    <Col sm={12} className='mb-3'>
                    <Form.Label htmlFor="subHeading">Client Review</Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder="Leave a comment here"
                        name="review"
                        value={inputFields.review}
                        onChange={handleChangeValidation}
                        style={{ height: '100px' }}
                        />
                        {errors.review ? (<p className="error">{errors.review} </p>) : null}
                    </Col>
                 </Row>
                
                 <Button type="submit" className='custom-btn btn-3'><span>Submit</span></Button>
                                
                 </form>
                 <center>
                 <Button onClick={viewTestimonial} className='btn-2 edit-btn'><span>View Testimonials</span></Button> 
                 </center>  
                  </Tab.Pane>
                  
                  {/* Edit Testimonials */}
                  <Tab.Pane eventKey="fourth">
                    <form onSubmit={handleUpdateSubmit}>
                    <Row>
                      <h4 className='pb-3'>Update Testimonial</h4>
                     {/*  <Link to={'#'} onClick={viewTestimonial} >View Testimonials</Link> */}
                    </Row>
                 <Row> 
                  {/* Client Image */}
                  <Col sm={6} className='mb-3'>
                        <Form.Label htmlFor="image">Client Image</Form.Label>
                  <img src={uploadedFileURL + editimage} alt="Uploaded content"/>
                        <Form.Control
                          type="file"
                          id="editimage"
                          aria-describedby="NameHelpBlock"
                          onChange={handleEditImage}
                        />
                      </Col>                  
                    <Col sm={12} className='mb-3'>
                    <Form.Label htmlFor="subHeading">Tittle</Form.Label>
                        <Form.Control
                          type="text"
                          id="Title"
                          aria-describedby="NameHelpBlock"
                          value={edittitle}
                        onChange={(e) => setEditTitle(e.target.value)}
                        />
                    </Col>
                    <Col sm={6} className='mb-3'>
                    <Form.Label htmlFor="subHeading">Client Name</Form.Label>
                        <Form.Control
                          type="text"
                          id="client"
                          aria-describedby="NameHelpBlock"
                          value={editclient}
                        onChange={(e) => setEditClient(e.target.value)}
                        />
                    </Col>
                    <Col sm={12} className='mb-3'>
                    <Form.Label htmlFor="subHeading">Client Review</Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder="Leave a comment here"
                        value={editreview}
                        onChange={(e) => setEditReview(e.target.value)}
                        style={{ height: '100px' }}
                        />
                    </Col>
                 </Row>
                 <Button type="submit" className='custom-btn btn-3'><span>Update</span></Button>
                 </form>        
                 <center>
                 <Button onClick={BackTestimonial} className='btn-2 back-btn'><span>Back</span></Button> 
                 </center>      
                  </Tab.Pane>
                  {/*  */}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Wrapper>
    </>
  )
}

export default AdminDashborad


const Wrapper = styled.section`
   width: 100%;
   padding:100px 0 100px;
   background:#f1f1f1; 
   .container {
    background: #fff;
    padding: 50px;
    border-radius: 10px;
    .bass-row {
    border: 1px solid #ccc;
    padding: 30px;
    border-radius: 10px;
    }
    .dropdown span {
      margin-right: 14px;
    }
    .nav-link span {
        padding-right: 10px;
        font-size: 18px;
    }
   .rgt-row {
    background: #ffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 1px 10px rgba(96,96,96,0.10);
    .nav-pills {
      background: #ededed;
     }
   }
   h2{
    padding:0 0 30px;
    font-size:25px;
    font-weight:600;
    color:#4B4B4B;
    text-align:center; 
   }
   .left-sidebar{
    background:#fff;
    padding:30px;
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 1px 10px rgba(96,96,96,0.10);
    
    a.active{background:#198754; color:#fff;}
    .nav-link{
      color:#4B4B4B;
      border-radius:0px;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 5px;
      margin: 0 0 2px;
    }
    .nav-link:hover{
      background:#198754;
      color:#fff;
    }
   }
   .tab-content{
    background: #fff;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0px 1px 10px rgba(96,96,96,0.10);
    }


  .tp-label {
    font-weight: 600;
    padding: 0 0 10px;
}
.dropdown-toggle {
  white-space: nowrap;
  width: 100%;
  text-align: start;
  &:after {
    position: relative;
    float: right;
    top: 12px;
}

}
.dropdown-menu.show {
  width: 100%;
  padding:0;
}

p.error {
  color: red;
}
.dropdown-item.active, .dropdown-item:active {
  color:#fff;
  text-decoration: none;
   background-color:#198754 ; 
}
.dropdown-item:hover {
  background: #198754;
  color:#fff;
}
.delete-btn {
  margin-left: 5px;
  background: red;
  border: red;
}
.edit-btn {
  background:#1D3F7C;
  border: #1D3F7C;
}
.submit-testimo {
  display: flex;
  justify-content: space-between;
}
#addlink {
  margin-top: 1px;
}
}

`;