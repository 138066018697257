import React from 'react'
import BlogSearch from "../components/Blog/BlogSearch";
import BlogBanner from '../components/Blog/BlogBanner';

const Blog = () => {
  return (
    <>
    <BlogSearch/>
    <BlogBanner/>
    </>
    
  )
}

export default Blog